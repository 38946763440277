.partIII-CQ-width {
  app-progress-bar {
    .items li .outer-container {
      width: 97%;
      @include mobile() {
        width: 100% !important;
      }
      &:first-child {
        width: 98.25%;
        @media screen and (min-width: 749px) and (max-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
          width: calc(99% - #{4px}) !important;
        }
      }
    }
  }
}
