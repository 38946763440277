.otp-modal{
    .modal-popup__header {
        margin-bottom: 0px;
        border-bottom: none;
    }
    
    .modal-popup__footer {
        margin-top: 0px;
        padding-top: 0px;
        border-top: none;
    }
    
    .modal-popup {
        max-width: 443px !important;
        top: 9%;
        padding: 0px;
    }
}