

.mainContainer {
    background: #fff;
    width: 1182px;
    @include mobile (){
        padding: 0;
        width: 100% !important;
    }
    @include tablet (){
        width: 100% !important;
    }
}
.help {
    padding-right: 4px;
    @include mobile (){
        padding: 5px 0 0 0;
    }
}
span {
    display: inline;
}
h1 {
    line-height: 40px;
    font-size: 34px !important;
    @include mobile (){
        font-size: 27px !important;
        line-height: 32px;
    }
}
h1, h4 {
    margin-bottom: 0;
}
h2 {
  margin: 0 !important;
}
.horizontal-top-border {
    @include mobile (){
        margin-left: 20px;
        margin-right: 20px;
    }
}
.page-title h1 {
    @include mobile (){
        // empty
    }
}
h4 .wrap {
    font-weight: normal;
    font-family: 'Roboto Medium', 'Roboto' ,sans-serif !important;
}
.instructional-text {
    font-size: 16px;
    line-height: 21px;
}
.btn {
    font-size: 16px !important;
    @include mobile (){
        width: 100%;
    }
}
.inp-float--error {
    margin-bottom: 45px !important;
    @include tablet (){
        margin-bottom: 65px !important;
    }
}
.edit-dob {
    .inp-float--error {
        margin-bottom: 45px !important;
        width: 30%;
    }
}
.inp-lbl {
    line-height: 21px !important;
    display:inline-flex;
}
.yellow-bg {
    border: 2px solid #822433;
    margin-bottom: 10px;
    margin-top: 10px;
    box-shadow: 0 0 4px 1px rgba(155,153,155,.9);
    background-color: #fff2f4!important;
}


.label-blue {
    color: #0D5DA6 !important;
}
.questindex {
    font-family: 'Roboto Medium', 'Roboto',sans-serif !important;
    font-size: 16px !important;
    line-height: 21px !important;
    font-weight: 500;
    display: inline-block;
    padding: 0 10px 0 10px;
    @include mobile (){
        display: inline;
    }
}

.header-bottom-border {
    border-bottom: 1px solid #BFBFBF;
}
.wrap {
    font-family: 'Roboto Regular', 'Roboto' , sans-serif !important;
    font-size: 16px !important;
    line-height: 21px !important;
    overflow-wrap: break-word;
    display: inline-block;
    @include mobile (){
        display: inline;
    }
}

@media screen and (min-width : 750px) and (max-width : 880px){
    .wrap {
        width: 78%;
    }
}

.label-top {
    display: flex;
    justify-content: flex-start;
}
.remove-bg {
    background-color: transparent !important;
}
.remove-bg-8 {
    background-color: transparent !important;
}
.remove-bg-9 {
    background-color: transparent !important;
}
.alternate-bg-pg1 {
    .grid--col2:nth-child(2n + 1){
        background-color: #EDF6F8;
    }
    @include mobile (){
        .grid--col2 > div:first-child {
            background-color: #EDF6F8;
        }
        .grid--col2:nth-child(2n + 1){
            background-color: transparent !important;
        }
        .remove-bg.grid--col2 > div:first-child {
            background-color: transparent !important;
        }
    }
}
.alternate-bg-pg1 > div:nth-of-type(even){
    background-color: #EDF6F8;
}
.alternate-bg-pg1 > div:nth-of-type(odd){
    background-color: #FFFFFF;
}
.flex-table__row {
    display: inline-block !important;
}
.flex-table__cell {
    padding: 10px 0 10px 0;
    width: 70%;
    @include mobile (){
        width: 100%;
    }
    @include tablet(){
        width: 60%;
    }
    // fix1
    @include mobile() {
        padding-left: 30px;
        .answer {
            padding-left: 35px;
        }
    }
}
.flex-table__cell.radio {
    width: 35%;
    @include tablet(){
        width: 48%;
    }
    @include mobile (){
        width: 100%;
        padding-top: 0;
        padding-bottom: 10px;
    }
}
.radio label:nth-child(2) {
    padding-right: 20px;
}
.radio label:nth-child(4) {
    padding-right: 10px;
}
.flex-table__row:last-child .flex-table__cell {
    padding-bottom: 10px;
}
.no-grid {
    width: 85%;
    padding-right: 20px;
    @include mobile (){
        padding-right: 0;
    }
    p {
        display: inline-block;
    }
    p.wrap {
        width: 94%;
        @include tablet (){
            width: 91%;
        }
        @include mobile (){
            width: 85%;
        }
    }
    display: flex;
    flex-wrap: wrap;
    .wrap {
        font-family: 'Roboto Medium', 'Roboto', sans-serif !important;
    }
}
#no-grid {
    padding: 10px 0 10px 0;
    @include mobile(){
        padding: 10px !important;
    }
    .radio {
        display: flex;
        justify-content: flex-end;
        @include mobile (){
            justify-content: flex-start;
            // fix1
            padding-left: 37px;
        }
    }
    .radio label:nth-child(2) {
        padding-right: 20px;
        @include mobile (){
            padding-right: 20px;
        }
    }
}
.question-radio {
    width: 80%;
    @include mobile (){
        width: 100%;
    }
}
.radio {
    text-align: right;
}
@include mobile (){
    .grid--col2 .grid__item {
        width: 100% !important;
    }
    .radio {
        text-align: left;
    }
    .grid__item {
        width: 100%;
        .display-inline {
            display: block;
            width: 100%;
        }
    }
    // .radio {
    //     padding-left: 65px;
    // }
    .flexcontainer {
        flex-wrap: wrap;
    }
    .help {
        padding-right: 0;
    }
    .no-grid {
        width: 100%;
        padding-bottom: 10px;
        .radio {
            padding-left: 42px;
        }
    }
    .wrap {
        width: 87%;
    }
}

h4.flexcontainer {
    justify-content: flex-start !important;
}

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #bfbfbf;
    margin: 1em 0;
    padding: 0;
}
.prefillData {
    display: flex;
    flex-direction: row;
    font-family: 'Roboto Regular', 'Roboto' , sans-serif;
    font-size: 16px;
    color: #37424A;
    width: 100%;
    padding: 20px 0 20px 0;
    @include mobile(){
        width: 84%;
        flex-direction: column;
        padding: 20px 0 0 0;
    }
}
.prefillData-dob {
    padding: 0 10px 20px 0 !important;
    @include mobile(){
        width: 100%;
        padding: 0 20px 20px 0 !important;
    }
}
.data-col {
    width: 29%;
    padding-right: 20px;
    span{
        font-weight: 500;
    }
    @include tablet(){
        width: 28%;
    }
    @include mobile(){
        padding-bottom: 10px;
        width: 100%;
        padding-right: 0;
    }
    overflow-wrap: break-word;
}
// fix for IE 10+
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .data-col {
        -ms-word-break: break-all;
    }
    .wrap {
        word-wrap: break-word;
    }
    .flex-table__cell.radio{
        vertical-align: middle;
        padding:10px 0 10px 3px;
    }
    .flex-table__cell{
        max-width:70%;
        display: block;
    }
    .flex-table {
        display: block;
    }
    #nestedQuestions .nested-label {
        width: 15% !important;
    }
    .review {
        #nestedQuestions .nested-label {
            width: 26% !important;
        }
    }
    @include mobile(){
        .step-hr.step {
            display: inline;
        }
        .data-col-suffix {
            width: 90% !important;
        }
    }
}
.data-col-edit-link {
    padding-top: 20px;
    padding-right: 10px;
    text-align: right;
    color: #036d9b;
    font-weight: 500;
    font-size: 16px;
    @include mobile(){
        // padding-right: 0 !important;
    }
}
.data-col-suffix {
    width: 12% !important;
    padding-right: 0;
    @include tablet(){
        width: 15% !important;
    }
    @include mobile(){
        padding-bottom: 10px;
        width: 100% !important;
    }
}
.data-col-dob {
    width: 50%;
    padding-right: 0;
    @include mobile(){
        width: 100%;
        padding-bottom: 0;
    }
}
.edit-mobile-dob {
    display: flex;
    flex-direction: row;
    width: 40%;
    @include mobile(){
        width: 100%;
    }
}
.edit-mobile-dob .form-field-err {
    padding-bottom: 20px;
}
.edit-mobile-dob .edit-dob {
    width: 96%;
    padding-right: 10px;
    @include mobile(){
        padding-right: 0;
    }
}
.edit-mobile-dob .data-col-suffix {
    width:25%;
    @include mobile(){
        padding-right: 20px;
    }
    padding-right: 20px;
}
.edit-prefill-data {
    .data-col {
        width: 29.7%;
        @include mobile(){
            width: 100% !important;
        }
    }
    padding: 25px 0 0 0 !important;
    @include mobile(){
        width: 100% !important;
        padding: 20px 0 0 0 !important;
        .data-col-suffix {
            display: none;
        }
    }
}
.edit-prefill-data-dob {
    padding: 0 !important;
    @include mobile(){
        width: 100% !important;
        padding: 0 !important;
    }
    .data-col-suffix {
        display: none;
        @include mobile(){
            display: inline-block;
            width: 25% !important;
        }
    }
}
.paddingl10 {
    padding-left: 10px;
}

.ltc {
    .inp-float__placeholder--float {
        background-color: #ffffff;
    }
}

.question-tracking {
    font-size: 18px !important;
    color: #616B72;
    padding-top: 15px;
    @include mobile(){
        padding-left: 0;
    }
    .answer-count {
        color: #37424a !important;
    }
}
.page-title {
  .title-track {
    width: 83%;
    @include mobile(){
        width: 84%;
    }
  }
  @include mobile() {
    flex-wrap: nowrap;
  }
}
// #no-grid {
   
// }
#nestedQuestions {
    .sub-question {
        width: 97%;
    }
    .wrap {
        width: 97%;
        @include mobile() {
            width: 89%;
        }
    }
    .nested-label {
        width: 15%;
        @include mobile() {
            width: 12%;
        }
        @include tablet() {
            width: 20%;
        }
    }
    .radio {
        @include mobile() {
            // fix1
            padding-left: 70px;
        }
    }
    // fix1
    @include mobile() {
        .reviewSubQuestions .flex-table__cell .answer {
            padding-left: 5px;
        }
    }
}

.edit-link-nogrid {
  width: 100%;
}
.alternate-bg > div:nth-of-type(even){
    background-color: #EDF6F8;
}
@include mobile() {
    .grid__item {
        padding-left: 10px;
        padding-right: 10px;
    }
    .edit-link {
        padding: 0 10px 0 10px;
    }
    .grid-questions {
        padding: 10px 0 10px 0;
    }
    .nested-quest-main {
        padding-bottom: 10px;
        .question-radio {
            padding-bottom: 10px;
        }
        // fix1
        .radio{
            padding-left: 37px;
        }
    }
}
.review {
    .inp-lbl--chkbox::before {
        margin-top: 3px;
        padding-right: 20px !important;
    }
    .ent-checkbox label::before, .ent-checkbox label::after {
        margin-top: 3px;
    }
    .grid-questions {
        padding: 10px;
    }
    #no-grid {
        padding: 10px;
    }
    .alternate-bg > div:nth-of-type(odd){
        background-color: #EDF6F8;
    }
    .alternate-bg > div:nth-of-type(even){
        background-color: #FFFFFF;
    }
    padding: 10px;
    .prefillData {
        padding: 20px !important;
        @include mobile() {
            padding: 20px 20px 0 20px !important;
        }
    }
    .prefillData-dob {
        padding: 0 0 20px 20px !important;
    }
    .data-col-edit-link {
        padding: 20px 20px 0 0;
    }
    .horizontal-top-border {
        margin: 0;
    }
    .data-col-suffix {
        width: 11%;
        @include mobile() {
            width: 100%;
        }
    }
    .no-grid {
        width: 100% !important;
        @include mobile() {
            padding-right: 0;
        }
    }
    .no-grid p.wrap {
        width: 94%;
        @include tablet() {
            width: 91%;
        }
        @include mobile() {
            width: 84%;
        }
    }
    .question-radio {
        width: 130%;
    }
    .wrap {
        width: 100%;
        @include mobile() {
            width: 80%;
        }
    }
    .wrap-check {
        @include mobile() {
            width: 100%;
            display: block;
        }
    }
    .review-edit {
        color: #036d9b;
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
    }
    .review-edit .btn--link {
      padding: 0 !important;
    }
    .answer {
        font-family: 'Roboto Medium', 'Roboto' , sans-serif;
        font-size: 16px;
        line-height: 21px;
        font-weight: bold;
        color: #37424A;
    }
    .edit-link-nogrid {
        width: 95%;
    }
    .confirmation-box {
        display: flex;
    }
    #nestedQuestions .nested-label {
        width: 20%;
    }
    .inp-lbl {
        padding-left: 0;
    }
    @include mobile() {
        .edit-link-nogrid {
            width: 83%;
            .radio {
                padding-left: 40px !important;
            }
        }
        #nestedQuestions .nested-label {
            width: 13%;
        }
        .confirmation-box {
            padding-left: 10px;
            padding-right: 10px;
        }
        .grid-questions {
            padding: 10px 10px 0 10px;
        }
        #no-grid {
            padding: 10px !important;
        }
        padding: 0;
        .flex-table__cell {
            width: 100% !important;
        }
        .edit-link {
            flex-wrap: nowrap !important;
            padding: 0;
        }
        .grid__item{
            padding: 0;
        }
        // .edit-link-nogrid {
          
        // }
        .question-radio {
            width: 100%;
        }
        .sub-question {
            padding-right: 10px;
        }
        .horizontal-top-border {
            margin-left: 20px;
            margin-right: 20px;
        }
        .instructional-text {
            padding-left: 20px;
            padding-right: 20px;
        }
        .unanswered-warning {
            padding-left: 20px;
            padding-right: 20px;
        }
    }
}
.review-container {
    border: 1px solid #BFBFBF;
    padding-top: 10px;
    padding-bottom: 10px;
    position: relative;
    @include mobile() {
        border: none;
        padding: 0;
    }
}
.review-container-bottom {
    border: 1px solid #BFBFBF;
    border-top: none;
    padding: 10px;
    position: relative;
    @include mobile() {
        border: none;
        padding: 0;
    }
}
.arrow {
    position: absolute;
    left: -2px;
    top: 10px;
    overflow: hidden;
    height: 60px;
    width: 40px;
    border-bottom-right-radius: 60px;
    border-top-right-radius: 60px;
    border: 1px solid #cdd2d6;
    border-left-color: #fff;
    background: #fff;
    .fa-2x {
        font-size: 2.5em;
        margin-top: 9px;
    }
    @include mobile() {
      top: 2px;
      left: -5px;
    }
}
.review-heading {
    display: flex;
    justify-content: flex-start;
    padding-left: 45px;
    padding-top: 15px;
    padding-right: 20px;
    padding-bottom: 20px;
    h2 {
        font-family: 'Roboto Regular', 'Roboto' , sans-serif;
        font-size: 22px;
        line-height: 30px;
        font-weight: 400;
    }
    @include mobile() {
        padding-left: 20px;
        padding-right: 0;
        margin-left: 20px;
        margin-right: 20px;
        border-top: 1px solid #BFBFBF;
    }
}
.review-checkbox-wrap {
    padding: 10px 20px 10px 40px;
}
.reviewSubQuestions {
    padding-right: 45px;
    @include mobile() {
        padding-right: 0;
        // fix1
        .flex-table__cell .answer {
            padding-left: 0;
        }
    }
    .flex-table__cell {
        width: 80%;
    }
    .flex-table__cell.radio {
        width: 15% !important;
    }
}
#reviewCheckBox:disabled + .inp-lbl--chkbox {
    cursor: default;
}
@include mobile() {

    @media screen and (min-width: 650px) and (max-width: 750px){
        #nestedQuestions {
            .nested-label {
                width: 7%;
            }
            .radio {
                padding-left: 75px;
            }
        }
    }
    @media screen and (min-width: 550px) and (max-width: 650px){
        #nestedQuestions {
            .nested-label {
                width: 8%;
            }
            .radio {
                padding-left: 75px;
            }
        }
    }
    @media screen and (min-width: 451px) and (max-width: 550px){
        #nestedQuestions {
            .nested-label {
                width: 10%;
            }
            .radio {
                padding-left: 70px;
            }
        }
    }

}

.fa-times {
    cursor: pointer;
}

.display-flex {
  display: flex;
}

.updt-phn{
    font-size: 14px;
    outline: none;
    background: none;
    border: none;
    color: #036d9b;
    padding: 0px;
    cursor: pointer;
    margin-left: 5px;
  }
  .updt-phn:focus, .updt-phn:hover {
    text-decoration: underline;
  }
  .disp-webkit-box{
    display: -webkit-box;
  }