.spinner {
    height:30%;
    width:100%;
    background: #ffffff;
    padding:20px 20px 30px 20px;
    text-align:center;
}
.spinner-waiting {
    height:50px;
    width:50px;
    border-radius:50%;
}
.content {
    background:#edf6f8;
}

div.hr {
    width:100%;
    height: 6px;
    background: #0D5DA6;
}
div.hr:after {
    content:'';
    position: absolute;
    border-style: solid;
    border-width: 15px 15px 0;
    border-color: #0D5DA6 transparent;
    display: block;
    width: 0;
    z-index: 1;
   // top: 75px;
    left: 48%;
    @include mobile(){
        left: 46%;
    }
}

#circle {
    width: 60px;
    height: 60px;
    background: #ffffff;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    border:4px solid #0D5DA6;
    display:inline-block;
}

.docu-sign {
    .modal-popup-cont {
        padding: 0;
        @include mobile(){
            padding: 20px;
        }
    }
    .modal-popup {
        width: 714px;
        padding: 0;
        @include mobile(){
            width: 100%;
            margin-top: 0 !important;
        }
    }
    .modal-popup__close {
        display: none;
    }
    .header {
        font-family: 'Roboto Condensed Light', 'Roboto' , sans-serif;
        font-weight: 300;
        font-style: normal;
        font-size: 34px;
        color: #37424A;
        line-height: 40px;   
        @include mobile(){
            font-size: 27px;
            line-height: 32px;
        } 
    }
    .text {
        font-family: 'Roboto Regular', 'Roboto' , sans-serif;
        font-weight: 300;
        font-style: normal;
        font-size: 18px;
        color: #37424A;
        line-height: 26px;  
        @include mobile(){
            font-size: 16px;
            line-height: 21px;
        }   
    }
    .progress-bar-container {
        height: 20px;
        background-color: #DCDEE0;
        margin-bottom: 20px;
        margin-top: 10px;
        .progress-bar-width {
            height: 20px;
            width: 1%;
            background-color: #0D5DA6;
            .progress-bar-percentage {
                margin-left: 10px;
                padding-left: 105%;
                font-family: 'Roboto Regular', 'Roboto' , sans-serif;
                font-size: 16px;
                color: #37424A;
            }
        }
        .progress-bar-width-5 {
            width: 5%;
            .progress-bar-percentage {
                margin-left: 0;
            }
        }
        .progress-bar-width-10 {
            width: 10%;
            .progress-bar-percentage {
                margin-left: 0;
            }
        }
        .progress-bar-width-15 {
            width: 15%;
            .progress-bar-percentage {
                margin-left: 0;
            }
        }
        .progress-bar-width-20 {
            width: 20%;
            .progress-bar-percentage {
                margin-left: 0;
            }
        }
        .progress-bar-width-25 {
            width: 25%;
            .progress-bar-percentage {
                margin-left: 0;
            }
        }
        .progress-bar-width-30 {
            width: 30%;
            .progress-bar-percentage {
                margin-left: 0;
            }
        }
        .progress-bar-width-35 {
            width: 35%;
            .progress-bar-percentage {
                margin-left: 0;
            }
        }
        .progress-bar-width-40 {
            width: 40%;
            .progress-bar-percentage {
                margin-left: 0;
            }
        }
        .progress-bar-width-45 {
            width: 45%;
            .progress-bar-percentage {
                margin-left: 0;
            }
        }
        .progress-bar-width-50 {
            width: 50%;
            .progress-bar-percentage {
                margin-left: 0;
            }
        }
        .progress-bar-width-55 {
            width: 55%;
            .progress-bar-percentage {
                margin-left: 0;
            }
        }
        .progress-bar-width-60 {
            width: 60%;
            .progress-bar-percentage {
                margin-left: 0;
            }
        }
        .progress-bar-width-65 {
            width: 65%;
            .progress-bar-percentage {
                margin-left: 0;
            }
        }
        .progress-bar-width-70 {
            width: 70%;
            .progress-bar-percentage {
                margin-left: 0;
            }
        }
        .progress-bar-width-75 {
            width: 75%;
            .progress-bar-percentage {
                margin-left: 0;
            }
        }
        .progress-bar-width-80 {
            width: 80%;
            .progress-bar-percentage {
                margin-left: 0;
            }
        }
        .progress-bar-width-85 {
            width: 85%;
            .progress-bar-percentage {
                margin-left: 0;
            }
        }
        .progress-bar-width-90 {
            width: 90%;
            .progress-bar-percentage {
                margin-left: 0;
            }
        }
        .progress-bar-width-95 {
            width: 95%;
            .progress-bar-percentage {
                margin-left: 0;
            }
        }
    }
 }

#docuSignSuccess {
    #circle {
        border: 4px solid #248476;
    }
    div.hr {
        background: #248476;
    }
    div.hr:after {
        border-color: #248476 transparent;
    }
    .btn {
        font-size: 16px;
        font-family: "Roboto Medium" ,"Roboto" , sans-serif;
        background: #248476;
        &:hover {
            background-color:  #00A18F;
        }
    }
}

div.bottom-triangle:after {
    content:'';
    position: absolute;
    border-style: solid;
    border-width: 10px 10px 0;
    border-color: #edf6f8 transparent;
    display: block;
    width: 0;
    z-index: 1;
    left: 49%;
    @include mobile(){
        left: 48%;
    }
}    
#redirectBtn {
    position: relative;
    margin: auto;
    text-align: center;
    background: #ffffff;
    .btn{
        width: 320px;
        @include mobile() {
            width: 100%;
        }
    }
}
.bgcolor-white {
    background-color: rgba(255,255,255, 1)
}