.welcome {
  @include mobile() {
    padding: 0px 20px;
  }
  .help {
    padding-right: 3px !important;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    @include mobile() {
      margin-top: 20px;
    }
  }
  .header-container {
    position: relative;
    margin-bottom: 30px;
    @include mobile() {
      position: initial;
      margin-top: 20px;
      margin-left: -20px;
      margin-right: -20px;
    }
    @include tablet() {
      position: initial;
      margin-top: 20px;
    }
  }
  .header-container_text {
    background-color: $ent-color;
    //position: absolute;
    // left: 0;
    // bottom: auto;
    // top: 90%;
    width: 100%;
    padding: 20px;
    @include mobile() {
      position: inherit;
      display: block;
      width: 100%;
      padding: 25px;
    }
    @include tablet() {
      position: inherit;
      display: block;
      width: 100%;
      padding: 20px;
    }
  }
  .header-container_text h1 {
    i {
      padding-right: 0px;
    }
    color: $color-heading-white;
    margin: 0;
  }

  .welcome-intro {
    font-weight: lighter;
    margin-bottom: 30px;
    @include mobile() {
      margin-bottom: 35px;
    }
  }
  .product-intro {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    @include mobile() {
      display: block;
      margin-bottom: 40px;
    }
  }
  .product-intro_section-text {
    width: 560px;
    @include mobile() {
      width: 100%;
    }
  }
  .product-intro_section-text:first-child {
    margin-right: 40px;
    @include mobile() {
      margin-bottom: 35px;
    }
  }
  .product-intro_section-text h2 {
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    text-transform: uppercase;
    border-bottom: 1px solid #d8d8d8;
    padding-bottom: 10px;
  }
  .product-intro_section-text ul li {
    font-size: 15px;
    line-height: 25px;
    color: #37424a;
  }
  .list-dotted li + li {
    margin-top: 15px;
  }
  .list-dotted li::before {
    font-size: 4px;
    color: #37424a;
  }

  .form-container {
    position: relative;
    padding: 20px;
    background-color: $color-info-bg;
    @include mobile() {
      margin-left: -20px;
      margin-right: -20px;
    }
  }
  .form-container_row {
    @include mobile() {
      margin-bottom: 20px;
    }
  }
  .margin-bottom {
    margin-bottom: 40px;
    @include mobile() {
      margin-bottom: 20px;
    }
  }

  .form-container .welcome-form-intro {
    font-size: 18px;
    line-height: 26px;
    font-weight: 300;
    padding-bottom: 20px;
    margin-bottom: 30px;
    border-bottom: 1px solid #d8d8d8;
  }
  .form-container_static-text {
    @include mobile() {
      margin-bottom: 20px;
    }
  }
  .form-container_static-text p {
    font-weight: 300;
    font-size: 15px;
    line-height: 21px;
    font-family: 'Roboto Medium','Roboto', sans-serif;
  }
  .form--control {
    width: 25%;
    display: inline-block;
    position: relative;
    vertical-align: top;
    margin-right: 20px;

    @include above(1220px) {
      max-width: 270px;
    }
    @include desktop() {
      width: 23.3%
    }
    @include tablet() {
      width: 22.8%
    }
    @include below(790px) {
      width: 21.8%
    }
    @include mobile() {
      width: 100%;
    }
  }
  .form--control_suffix {
    margin-right: 0px;
  }
  .noMarginRight {
    margin-right: 0px;
  }
  .lfg-radio_question {
    margin-bottom: 10px;
  }
  .lfg-radio_section .lfg-radio_label {
    margin-right: 40px;
    color: #37424a;
  }
  .ent-radio .lfg-radio_section {
    margin-bottom: 20px;
  }
  .lfg-radio_section_nomargin {
    margin-bottom: 0px !important;
  }

  .inp-float__placeholder--float {
    background-color: $color-info-bg;
  }
  .lfg-cal:not(.calendar--err) .lfg-cal__date-input {
    background-color: #fff;
  }
  .inp-float__placeholder {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden !important;
    max-width: calc(100% - #{20px});
  }
  .search-control input {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden !important;
    padding-right: 25px;
  }
  .lfg-cal-input-wrap .inp-float__placeholder {
    max-width: calc(100% - #{38px});
  }
  div.bottom-triangle-welcome {
    position: absolute;
    border-style: solid;
    border-width: 20px 20px 0;
    border-color: $color-info-bg transparent;
    display: block;
    left: 50%;
    margin-left: -20px;
    bottom: -20px;
  }
  .search-control {
    margin-bottom: 20px;
  }
  .search-control .inp-float {
    margin-bottom: 0px;
  }
  .form--dropdown {
    margin-bottom: 20px;
  }

  // IE font issue
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .form-container .welcome-form-intro {
      font-size: 18px;
      line-height: 26px;
      font-weight: lighter;
      padding-bottom: 20px;
      margin-bottom: 30px;
      border-bottom: 1px solid #d8d8d8;
    }

  .form-container_static-text p {
      font-weight: lighter;
      font-size: 15px;
      line-height: 21px;
      font-family: 'Roboto Medium','Roboto', sans-serif;
    }
  }

  .button-container {
    text-align: center;
    margin-top: 40px;
    display: block;
    @include mobile() {
      text-align: left;
    }
  }
  .button-container .confirm-button {
    margin-top: 25px;
  }

  .value-change-section {
    position: relative;
    background-color: #fff;
    padding: 25px 20px 20px;
    margin-bottom: 30px;

    @include mobile() {
      padding: 25px 10px 20px 10px;
      margin-bottom: 20px;
    }
  }

  .value-change-section:before {
    content: '';
    position: absolute;
    border-style: solid;
    border-width: 15px 15px 0;
    border-color: #edf6f8 transparent;
    display: block;
    top: 0px;
    left: 10px;
  }
  .value-change-section.desktop-dob-section:before {
    left: calc(25% + #{30px});

    @include desktop() {
      left: calc(23.3% + #{30px});
    }
    @include tablet() {
      left: calc(22.8% + #{30px});
    }
    @include below(790px) {
      left: calc(21.8% + #{30px});
    }
  }
  .value-change-section .value-change-buttons {
    display: flex;
    margin-top: 20px;

    @include mobile() {
      display: block;
    }
  }
  .value-change-buttons ent-left-button-tray:nth-child(2) .btn--link {
    vertical-align: middle;
    padding: 16px 32px 16px 0px;

    @include mobile() {
      padding: 0px;
      margin-top: 20px
    }
  }

  .dob-changed {
    margin-bottom: 10px;

    @include mobile() {
      margin-bottom: 20px;
    }
  }
  .dob-changed .form--control {
    margin-bottom: 10px;

    @include mobile() {
      margin-bottom: 20px;
    }
  }
  .dob-changed .form--control .inp-float{
    margin-bottom: 0px;
  }
  .dob-changed .dob--control {
    @include mobile() {
      margin-bottom: 10px;
    }
  }

  .ssn-instructional-text {
    margin-bottom: 20px;
  }

  /** Disable field scss starts */
  .inp-float__inputbox[readonly] + .inp-float__placeholder.inp-float__placeholder--float,
  .inp-float__inputbox[disabled] + .inp-float__placeholder.inp-float__placeholder--float {
    background-image: linear-gradient(#fff, #d8d8d8) !important;
  }

  .inp-float__inputbox[readonly] + .inp-float__placeholder,
  .inp-float__inputbox[disabled] + .inp-float__placeholder {
    color: #37424a !important;
    background-color: #d8d8d8 !important;
  }

  .inp-float__inputbox[disabled] {
    color: #37424a !important;
    background-color: #d8d8d8 !important;
    border-color: #bfbfbf !important;
    // fix for iphone
    opacity: 1 !important;
    -webkit-text-fill-color: #37424a !important;

    &::-webkit-input-placeholder {
      -webkit-text-fill-color: transparent !important;
    }
    &::-moz-placeholder {
      color: transparent !important;
      -webkit-text-fill-color: transparent !important;
    }
    &:-ms-input-placeholder {
      color: transparent !important;
      -webkit-text-fill-color: transparent !important;
    }
  }
  /** Disable field scss ends */

  .inp-txt-confirmGovtId--warning {
    background: #f8e9df;
      border: 2px solid #ce6b27 !important;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden !important;
      padding-right: 40px;
  }


    .padRight5 {
        padding-right: 5px;
    }
    .mainContainer {
        background: #fff;
        width: 984px;
        @include mobile (){
            width: 100%;
        }
    }
    .leftSection {
        padding-right: 20px;
        width: 50%;
        @include mobile (){
            width: 100%;
            padding-right: 0;
            margin-right: 0;
        }
    }
    .rightSection {
        padding-left: 20px;
        width: 50%;
        @include mobile (){
            width: 100%;
            padding-left: 0;
            margin-left: 0;
        }
    }

    .leftSection,
    .rightSection {
        .flexcontainer {
            padding-bottom: 20px;
            p {
                display: inline-block;
            }
            p:first-child {
                padding-right: 10px;
                @include mobile (){
                  //empty block
                }
            }
            p:last-child {
                width: 98%;
                @include mobile (){
                    width: 95%;
                    @media screen and (max-width: 365px){
                        width: 94%;
                    }
                    // fix for IE 10+
                    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                        width: 97%;
                        @media screen and (min-width: 340px) and (max-width: 550px){
                            width: 95%;
                        }
                    }
                }
            }
        }
        .lower-details {
            p:first-child {
                padding-right: 10px;
                @include mobile (){
                  //empty block
                }
            }
            p:last-child {
                width: 95%;
                @include mobile (){
                    width: 90%;
                    @media screen and (max-width: 365px){
                        width: 88%;
                    }
                    // fix for IE 10+
                    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                        width: 94%;
                        @media screen and (min-width: 501px) and (max-width: 580px){
                            width: 93%;
                        }
                        @media screen and (min-width: 461px) and (max-width: 500px){
                            width: 92%;
                        }
                        @media screen and (min-width: 380px) and (max-width: 460px){
                            width: 90%;
                        }
                        @media screen and (max-width: 380px){
                            width: 89%;
                        }
                    }
                }
            }
        }
    }

    @include mobile (){
        .flexcontainer {
            flex-wrap: wrap;
        }
        .radiobtn {
            padding-bottom: 20px;
        }
        .help {
            padding-right: 0;
        }
    }
    .padBot {
        padding-bottom: 15px;
    }
    .padTop {
        padding-top: 25px;
    }
    span {
      display: block;
    }

    .welcomeimg .desktop {
        display: block;
    }
    .welcomeimg .mobile {
        display: none;
    }
    @include mobile (){
        .welcomeimg .desktop {
            display: none;
        }
        .welcomeimg .mobile {
            display: block;
        }
    }
    .welcomeimg img {
        width: 100%;
    }
    .square {
        width: 22px;
        height: 22px;
        transform: rotate(45deg);
        background-color: #edf6f8;
        text-align: center;
        position: absolute;
        top: 95%;
        @include mobile (){
            top: 97%;
            right: 46%;
        }
        right: 49%;
    }
    div.white-triangle:after {
        content:'';
        position: absolute;
        border-style: solid;
        border-width: 12px 12px 0;
        border-color: #FFFFFF transparent;
        display: block;
        width: 0;
        z-index: 1;
        left: 49%;
        @include mobile(){
            left: 48%;
            border-width: 10px 10px 0;
        }
    }
    .upperdiv{
        padding: 20px;
        .flexcontainer {
            justify-content: normal;
        }
    }
    .lowerdiv {
        background-color: rgba(237, 246, 248, 1);
        padding: 20px;
        .flexcontainer {
            justify-content: normal;
        }
        position: relative;
    }
    #welcomeBtn {
        position: relative;
        margin:auto;
        text-align: center;
        .btn{
            width: 320px;
        }
        @include mobile (){
            .btn{
                width: 100%;
            }
        }
    }
    #welBtn:enabled{
        background-color:  #248476;
        &:focus {
            background-color:  #00A18F;
        }
        &:hover {
            background-color:  #00A18F;
        }
    }
    .marginr20 {
        margin-right :20px;
    }
    .bgcolor-white{
        background-color: rgba(255,255,255, 1)
    }
    .radioText{
        width: 75%;
        @include mobile (){
            width: 100%;
        }
    }

    .bullets-header {
        font-size: 14px !important;
        padding-bottom: 10px !important;
        border-bottom: 1px solid #BFBFBF;
    }

    .leftSection p,
    .rightSection p {
        font-family: "Roboto Medium", "Roboto" , sans-serif;
        font-size: 16px;
        color:  #37424A;
        line-height: 20px;
        span {
            display: inline-block;
        }
    }
    .leftSection div:last-child {
        padding-bottom: 0px;
        @include mobile(){
            padding-bottom: 20px;
        }
    }
    .rightSection div:last-child {
        padding-bottom: 0px;
        @include mobile(){
            padding-bottom: 0;
        }
    }
    .flexcontainer{
        .radioText {
            font-family:"Roboto Regular" ,"Roboto" , sans-serif;
            font-size:16px;
            line-height:20px;
            color:#37424A;
        }
    }
    .btn{
        font-size:16px;
        font-family:"Roboto Medium" ,"Roboto" , sans-serif;
    }

    .welcomeText p{
        font-family: "Roboto Regular","Roboto" , sans-serif;
        font-size:16px;
        line-height:20px;
    }

    .heading-black {
        color:#37424A;
        font-family: "Roboto Condensed","Light" , sans-serif;
        @include mobile(){
            width: 82%;
        }
    }

  @mixin tablet-table-pro() {
    @media only screen and (max-width: 1160px) and (min-width: 750px) {
      @content;
    }
  }

  .product-agent {
    display: flex;
    @include mobile() {
      display: block;
    }
  }

  .disclaimer-text {
    @include mobile() {
      padding: 40px 20px 0px 0px;
    }
  }

  input[type='text'].inp-txt--err ~ .inp-float__pwd {
    display: none;
  }

  .gov-id-section {
    .inp-float__placeholder {
      max-width: calc(100% - #{40px});
    }
  }

}
