.medication {
    .medication-grid {
        // background-color: #ffff;
        // padding-bottom: 20px;
        // margin-top:10px;
    }
    textarea {
        height: 40px;
        resize: none;
        @include mobile() {
            height: 110px;
        }
    }
    .add-contact {
        margin-left: 10px;
        margin-right: 10px;
        padding: 10px 10px 10px 20px;
        @include mobile() {
            padding: 10px 10px 10px 10px;
            margin-left: 10px !important;
            width: 94%;
        }
    }
    .heading {
        padding-left: 40px;
        padding-right: 20px;
    }
    .phy-grid-child {
        padding: 0 20px 0 30px;
        width: 100%;
        @include mobile() {
            padding: 0 20px 0 35px;
        }
    }
    .phy-grid {
        @include mobile() {
            padding: 20px 13px 0 10px;
        }
    }
    .close {
        @include mobile() {
            left: -20px;
        }
    }

    .phy-grid-child-review {
        padding: 10px 20px 10px 40px;
        width:100%;
    }
    .alternate-bg-white {
        background-color: #ffff;
    }
}

// .yellow-bg .medication .bg-cyan,
.yellow-bg .medication .alternate-bg-white {
    background-color: transparent !important;
}

