
.mg-ais.content-wrapper {
  padding-top: 0px !important;
}
.mg-ais {
  .content-wrapper {
    padding-top: 0px !important;
  }
  .progress-hr,
  .wizard-buttons {
    display: none;
  }

  // Animation
  .halfway-bike-img {
    width: 110px;
    // overflow: hidden;
    // animation: halfwayBikeImg 4s ease-in 1s 1 normal both;
  }

  .existing-insurance-halfway {
    display: flex;

    @include mobile {
      display: block;
    }
  }

  .halfway-bike {
    align-self: flex-end;
    animation: halfwayBike 4s ease-in 1s 1 normal both;
    -webkit-animation: halfwayBike 4s ease-in 1s 1 normal both;

    @include mobile {
      display: flex;
      animation: halfwayBikeMobile 4s ease-in 1s 1 normal both;
      -webkit-animation: halfwayBikeMobile 4s ease-in 1s 1 normal both;
    }
  }

  .halfway-bike-space {
    display: none;
    @include mobile {
      display: block;
      animation: halfwayBikeSpace 4s ease-in 1s 1 normal both;
      -webkit-animation: halfwayBikeSpace 4s ease-in 1s 1 normal both;
    }
  }

  .halfway-text {
    align-self: center;
    overflow: hidden;
    white-space: nowrap;
    animation: halfwayText 4s ease-in 1s 1 normal both;
    -webkit-animation: halfwayText 4s ease-in 1s 1 normal both;

    h2 {
      margin: 0;

      @include mobile {
        text-align: center;
      }
    }

    @include mobile {
      animation: halfwayTextMobile 4s ease-in 1s 1 normal both;
      -webkit-animation: halfwayTextMobile 4s ease-in 1s 1 normal both;
    }
  }

  @mixin keyframes($kname) {
    @keyframes #{$kname} {
      @content;
    }
    @-webkit-keyframes #{$kname} {
      @content;
    }
    @-moz-keyframes #{$kname} {
      @content;
    }
  }

  @include keyframes(halfwayText) {
    0% { width: 0px; }
    100% { width: 90%; margin-bottom: 10px; }
  }

  @include keyframes(halfwayTextMobile) {
    0% { width: 0%; }
    100% { width: 100%; margin-bottom: 10px; }
  }

  @include keyframes(halfwayBikeMobile) {
    0% { max-height: 100%; opacity: 1; }
    25% { max-width: 25%; max-height: 100%; }
    75% { opacity: 0.5; max-width: 75%; max-height: 100%; }
    100% { opacity: 0; max-width: 100%; max-height: 0; }
  }

  @include keyframes(halfwayBikeSpace) {
    0% {width: 0%;}
    100% {width: 100%; height: 0px;}
  }

  @include keyframes(halfwayBike) {
    // 90% { opacity: 0.5;}
    // 100% { visibility: hidden; opacity: 0; max-width: 0px; max-height: 0px;}
    0% { max-height: 100%; opacity: 1; }
    25% { max-width: 25%; max-height: 100%; }
    75% { opacity: 0.5; max-width: 75%; max-height: 100%; }
    100% { opacity: 0; max-width: 100%; }
  }

  .halfway-text__name {
    text-transform: capitalize;
  }

}
