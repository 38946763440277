
// @import "public-config";

.login-header {
  margin-top: 28px;
  margin-left: auto;
  margin-right: auto;
  width: 180px;
  display: none;
}
@include mobile(){
    // .public .topheader {
    //     display: none;
    // }
    body.public{
        background: #ffffff;
    }
    // .public main {
    //     margin: 0 !important;
    // }

}
@include tablet(){
    // .public .topheader {
    //     display: none;
    // }
    body.public{
        background: #ffffff;
    }
    // .public main {
    //     margin: 0 !important;
    // }
}
@include desktop(){
    // .public .topheader {
    //     display: none;
    // }
    body.public{
        background: #ffffff;
    }
    // .public main {
    //     margin: 0 !important;
    // }
}
@include hd(){
    // .public .topheader {
    //     display: none;
    // }
    body.public{
        background: #ffffff;
    }
    // .public main {
    //     margin: 0 !important;
    // }
}
@include retina(){
    // .public .topheader {
    //     display: none;
    // }
    body.public{
        background: #ffffff;
    }
    // .public main {
    //     margin: 0px;
    // }
}


.main-container {
    box-sizing: border-box;
    // margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    width: 360px;
    border: 2px solid #BFBFBF;
    background-color: #FFFFFF;
      @include mobile(){
          // border-top: 2px solid #BFBFBF;
          // border-bottom: 2px solid #BFBFBF;
          border: 0 !important;
          border-left:none;
          border-right:none;
          width: 100%;
      }
}
.consentcontainer {
    width: 700px;
    @include mobile(){
        border-left:none;
        border-right:none;
        width: 100%;
    }
}

.login-steps {
    span {
        display: inline;
    }
}
.login .help {
    // text-align: right;
    padding-right: 0;
}
.step-indicator {
    font-size: 18px;
    color: #616B72;
    line-height: 26px;
}
.step-bold {
    color: #37424a;
    font-weight: bold;
}
.upper-box {
    padding: 20px;
}
.header-text  {
    font-family: 'Roboto Light', 'Roboto' , sans-serif;
    font-size: 34px;
    line-height: 40px;
    @include mobile(){
       font-size:27px;
       line-height: 32px;
    }
    margin-bottom: 0px;
    padding-bottom: 20px;
}
.main-content .header-body {
    font-family: 'Roboto Light', 'Roboto' , sans-serif;
    font-size: 18px;
    line-height: 26px;
    @include mobile(){
        font-size: 15px;
        line-height: 21px;
    }
}
.main-content .consent-header  {
    font-family: 'Roboto Light', 'Roboto' ,sans-serif;
    font-weight:100px;

}
.consent-outline {
    border: 1px solid #BFBFBF;
    background-color: #FFFFFF;
    padding: 10px 0 10px 10px;
}
.consent {
    height: 250px;
    @include mobile(){
        height: 200px;
    }
    //overflow: scroll;
    overflow-y: scroll;
    overflow-x: hidden;
    p {
        line-height: 20px;
        font-weight: 400 !important;
    }
}
.lower-box {
    background-color: #EDF6F8;
    padding: 20px;
    box-sizing: border-box;
}
.pad20 {
    padding: 20px 20px 0px 20px;
}
.inp-lbl--radio {
    font-family: 'Roboto Regular', 'Roboto' , sans-serif;
    font-size: 16px;
    color: #37424A;
    padding: 0 5px 0 30px;
}
.radiobtn {
    text-align: right;
}

// recaptcha

.recapthcha-container {
  width: 100%;
  display: inline-flex;
  padding-bottom: 25px;
}
.g-recaptcha {
    -webkit-transform: scaleX(1.04);
    -moz-transform: scaleX(1.04);
    -ms-transform: scaleX(1.04);
    transform: scaleX(1.04);
    padding-left: 7px;
    @include mobile() {
        -webkit-transform: scaleX(1.06);
        -moz-transform: scaleX(1.06);
        -ms-transform: scaleX(1.06);
        transform: scaleX(1.06);
        padding-left: 8px;
    }
}

.mg_lcn-div{
    padding: 20px 20px 0 0 ;
    font-size: 14px;
    font-family: 'Roboto Regular', 'Roboto' , sans-serif;
    @include mobile(){
        padding-left: 20px;
    }
}

@include mobile(){
    .form-error-msg {
        line-height: 14px;
        position: absolute;
        width: 175px;
    }
}



@media screen and (min-width : 414px) and (max-width : 749px){
    .g-recaptcha {
        -webkit-transform: scaleX(1.24);
        -moz-transform: scaleX(1.24);
        -ms-transform:scaleX(1.24);
        transform: scaleX(1.24);
        padding-left: 32px;
    }
}

@media screen and (min-width : 375px) and (max-width : 413px){
    .g-recaptcha {
        -webkit-transform: scaleX(1.11);
        -moz-transform: scaleX(1.11);
        -ms-transform:scaleX(1.11);
        transform: scaleX(1.11);
        padding-left: 15px;
    }
}

// fix for IE 10+
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .g-recaptcha {
        transform: scaleX(1.02);
        padding-left: 4px;
    }
    .recapthcha-container {
        padding-bottom: 20px;
    }
}

// fix for FireFox
@-moz-document url-prefix() {
    .recapthcha-container {
        padding-bottom: 20px;
    }
}

// over write the global styles

.inp-float__placeholder {
    font-size: $float-placeholder-font-size;
    line-height: 19px;
    overflow: visible !important;
}
.inp-float__placeholder--float {
    background-color: #EBF6F8;
    font-size: $float-placeholder-focussed-font-size
}
.inp-txt--err .inp-float {
    margin-bottom: 20px;
}
.alert-message {
    margin: 0px;
    padding: 11px 10px 10px 13px;
}

#loginBtn {
    font-weight: normal;
    font-family: 'Roboto Medium', 'Roboto' , sans-serif;
    #submit {
        width: 316px;
        font-size: 16px !important;
        @include mobile(){
            width: 100%;
        }
    }
    #decline, #consent {
        //width: 150px;
        font-size: 16px !important;
        @include mobile(){
            //width: 100% !important;
            .btn {
                display: block;
            }
        }
    }
    @include mobile(){
        .flexcontainer {
            display: block;
            justify-content: none;
        }
        .flexcontainer div:nth-child(1) {
            padding-bottom: 20px;
        }
    }

    .btn--whitebg-border{
        color: #036d9b;
        padding-left: 30px;
    }
    .consent-button {
        display: inline;
        padding-right: 30px;
        @include mobile(){
            display: block;
            padding-bottom: 20px;
            padding-right: 0;
        }
        .consent-btntext{
            font-family: 'Roboto Medium', 'Roboto' , sans-serif;
        }
    }
    .decline-button {
        display: inline;
        .consent-btntext{
            font-family: 'Roboto Medium', 'Roboto' ,sans-serif;
        }
    }


}

.inp-lbl {
    font-size: 16px;
    line-height: 26px;
    padding-left:30px;
    display: inline-flex;
}

@media screen and (max-width : 345px){
    .chkbox-container {
        padding-bottom: 20px;
    }
}

input[type=text].inp-txt--err {
    background: url(/src/assets/images/ex.png) calc(100% - 8px)/25px auto no-repeat #ffe7e7;
}

.btn {
    font-weight: 500;
}

.login {
    .header-body {
        font-weight: 300;
    }
    .consent-note {
        font-weight: 400;
        font-size: 18px;
        line-height: 26px;
        @include mobile(){
            font-size: 15px;
            line-height: 21px;
        }
    }
    .inp-float--error {
        margin-bottom: 20px !important;
    }
    .inp-float-captcha-off {
        .inp-float--error {
            margin-bottom: 40px !important;
        }
    }
    .inp-float-captcha {
        .inp-float {
            margin-bottom: 5px;
        }
    }
    .display-mobile {
        display: none !important;
        @include mobile(){
            display: block !important;
        }
    }
    .display-desktop {
        display: block !important;
        @include mobile(){
            display: none !important;
        }
    }
}

.add-bottom-padding {
    padding-bottom: 20px;
}

.guid-error{
    font-size: 16px !important;
    line-height: 21px !important;
    // margin: 0 6%;
    text-align: center;
    @include mobile() {
        // font-size: 14px !important;
        // line-height: 18px !important;
        text-align: center;
        // margin: 0.2%;
    }
}

@media screen and (min-width : 320px) and (max-width : 360px){
    .padding-guid {
        .alert-message {
            padding: 11px 10px 10px 12px !important;
        }
    }
}
// Decline Confirmation modal Popup
// #declineconfirmModal {
//     .modal-popup {
//         width: 575px;
//         // h1 {
//         //     font-size: 30px;
//         // }
//         // p {
//         //     font-size: 16px;
//         //     line-height: 20px;
//         // }
//         .footer-note {
//             font-size: 14px;
//             color: #616B72;
//             line-height: 16px;
//         }
//         // @include mobile(){
//         //     width: 100%;
//         //     //margin: auto 20px 20px 20px;
//         //    // top: 3%;
//         // }
//         // @include hd(){
//         //     top: 13%;
//         // }

//         .btn-tray__left button {
//             margin: 20px 32px 0 0 !important;
//         }
//         .alert-message--error p {
//             color: #f11 !important;
//         }
//     }
//     .alert-message{
//         width:90%;
//     }
//     .btn--link {
//         color: #036d9b;
//     }
// }



#reLoginBtn {
  display: flex;
  justify-content: space-between;
  margin: 0 20px 25px;
}


.sub-header-text{
margin: 0 0 20px;
}


.black-text {
  .alert-message--error .alert-message__content {
  color: #37424a;
  }
}
