//Block
.wizard-buttons {
  display: flex;
  margin-top: 20px;
  padding-top: 20px;
  padding-bottom: 10px;
  // border-top: 1px solid #DCDEE0;
  // margin-right: 20px;
  @include mobile() {
    align-items: center;
    //align: center; //before cognitive complexity fix
    //margin: 0 2px;
    display: block;
    padding: 20px !important;
  }
}

//Element
.wizard-buttons__article {
  padding: 0;
  border: none;
  // margin-right: 20px;
  // &:first-child {
  //     margin-right: 10px;
  //    margin-left: 10px;
  // }
  @include mobile() {
    margin-bottom: 20px;
    margin-right: 0px !important;
    padding: 0;
    border: none;
    &:last-child {
      margin-bottom: 0;
    }
    &:first-child {
      margin-bottom: 20px;
    }
  }
}

// .button-space {
//     &+.button-space {
//         margin-left: 20px;
//         @include mobile {
//             margin-left: 0;
//             margin-top: 20px;
//         }
//     }
// }

//Modifier
.wizard-buttons--right-buttons {
  display: flex;
  flex-direction: row-reverse;
  flex: 1;
  padding: 0;
  border: none;
  @include mobile() {
    padding: 0;
    border: none;
    display: block;
  }
}

.wizard-buttons--left-buttons {
  display: flex;
  // flex: 1;
  // padding: 0 0 0 10px;
  border: none;
  @include mobile() {
    padding: 0;
    border: none;
    display: block;
  }
}

// .btn--link-mobile,
// .btn {
//     @include mobile {
//         width: 100%;
//     }
// }

// .btn--link-mobile {
//     @include mobile {
//         padding: 0;
//     }
// }

.btn--link {
  // margin-top: 17px;
  // color: #0480B6;
  vertical-align: middle;
  padding: 16px 32px 16px 0px;
  &:hover {
    text-decoration: underline;
  }
  @include mobile {
    margin-top: 0px;
    padding: 0px !important;
  }
}

.btn--whitebg-border {
  color: #036d9b;
}
// .btn--no-right-margin {
//     margin-right: 0;
// }

.btn--whitebg-border:disabled {
  border: none;
  color: #fff;
}

.cases-steps-ca {
  lfg-life-mg-ais {
    app-case-search {
      .case-search {
        #case-steps {
          .case-steps {
            margin-left: 33% !important;
            width: 33% !important;

            @include mobile {
              margin-left: 0% !important;
              width: 100% !important;
            }
          }
        }
      }
    }
  }
}

.medical-ques-ca-partIII {
  app-progress-bar {
    .cases-steps-ca {
      lfg-life-mg-ais {
        app-case-search {
          .case-search {
            #case-steps {
              .case-steps {
                margin-left: 67% !important;
                width: 33% !important;

                @include mobile {
                  margin-left: 0% !important;
                  width: 100% !important;
                }
              }
            }
          }
        }
      }
    }
  }
}


.case-steps-ca-cq {
  lfg-life-mg-ais {
    app-case-search {
      .case-search {
        #case-steps {
          .case-steps {
            margin-left: 75% !important;
            width: 24.6% !important;

            @include mobile {
              margin-left: 0% !important;
              width: 100% !important;
            }
          }
        }
      }
    }
  }
}

.disable-navigate{ pointer-events:  none;}
