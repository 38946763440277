
.help-header {
	font-family: "Roboto Bold", "Roboto Regular", 'Roboto', sans-serif;
	font-size: 20px;
	line-height: 30px;
	font-weight: 500;
	color: #822433;
	margin-top: -8px;
	margin-bottom: 30px;
	display: inline-block;
	width: 270px;
}

.help-section-text {
	margin-bottom: 25px;
}

.help-section-text p {
	font-family: "Roboto Regular", 'Roboto',sans-serif;
	font-size: 14px;
	line-height: 18px;
	color: #37424a;
	font-weight: 400;
}

.help-section-text header {
	font-family: "Roboto Bold", "Roboto Regular", 'Roboto',sans-serif;
	font-size: 14px;
	line-height: 18px;
	font-weight: 700;
    color: #37424a;
    height: 100%;
    background-color: #EDF6F8;
}

.help-section {
	background-color: #edf6f8;
    padding: 20px;
    width: 320px;
    // height: 100%
}

.help-section:hover {
    width: 320px;
}

.cross-mark {
	border-width: 0px;
	position: absolute;
	right: 0px;
	top: -1px;
	width: 20px;
	height: 20px;
	opacity: 0.5;
	cursor: pointer;
	background: url('../../../assets/images/cross.png') 1px 1px/17px no-repeat transparent;
}

.header-cross {
	position: relative;
}

// help animation
.content-wrapper {
	position: relative;
}
.wrapper {
	position: absolute;
	overflow: hidden;
	width: 320px;
	height: 100%;
	right: 0;
	//z-index: 1000;
}
.slide-left {
	position: absolute;
	right: -320px;
	width: 320px;
	height: 100%;
	-webkit-animation: slide-out 1s forwards;
	animation: slide-out 1s forwards;
}
@-webkit-keyframes slide-out {
	100% { right: 0; }
}
@keyframes slide-out {
	100% { right: 0; }
}

.slide-right {
	position: absolute;
	right: 0px;
	width: 320px;
	height: 100%;
	-webkit-animation: slide-in  1s forwards;
	animation: slide-in  1s forwards;
}
@-webkit-keyframes slide-in {
	100% { right: -320px; }
}
@keyframes slide-in {
	100% { right: -320px; }
}

.animat .slide-right {
	right: -320px;
	 -webkit-animation: slide-in1  1s forwards;
	 animation: slide-in1  1s forwards;
}
@-webkit-keyframes slide-in1 {
	100% { right: -600px; }
}
@keyframes slide-in1 {
	100% { right: -600px; }
}

.wrapper-show {
	z-index: 999;
}
.wrapper-hide {
	z-index: -999;
}

#closeSlider:focus {
    outline: 1px dotted gray;
}

.customer-service-option_animation {
  display: inline !important;
  margin-left: 2px;
  font-size: 12px;
  line-height: 18px;
}
