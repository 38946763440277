.medicalpart3 {

    @import "common-form";
    .prefillData {
        width: 100%;
        padding: 0 0 20px 0;
        @include mobile(){
            padding: 0 !important;
        }
    }
    .data-col-edit-link {
        padding-top: 0;
    }
    .prefillData-dob {
        padding: 0 0 20px 0 !important;
    }
    @include mobile(){
        ul li:nth-child(1) {display: none;}
        ul li:nth-child(2) {display: none;}
        .add-contact {
           // margin: 0 !important;
            margin-left:33px !important;
            width:88%;
        }
        .flex-table__cell {
            padding-left: 20px;
        }
        .radio {
            padding-left: 55px;
        }
        .padding-leftright10 {
            padding-left: 10px;
            padding-right: 10px;
        }
        // .page-title .title-track {
        //     width: 79%;
        // }
    }
    textarea {
        height: 60px !important;
    }
    .grid-bg > div:nth-of-type(odd){
        background-color: #EDF6F8;
    }
    .blank-parent-quest.flexcontainer {
        justify-content: flex-start;
    }
    .blank-parent-quest {
        width: 100%;
        .flexcontainer11 {
            flex-direction: column;
            justify-content: flex-start;
            padding-bottom: 20px;
            .flexcontainer {
               .flex-table__cell {
                   padding: 0;
                   width: 79%;
                }
                .flex-table__cell.radio {
                    width: 21%;
                }
            }
        }
        .subquest-wrapp-blkparent {
            width: 100%;
            @include mobile() {
                width: 88%;
            }
        }
        .subquest-wrapp-blkparent > div:last-of-type {
            padding-bottom: 10px !important;
        }
        .label-top .questindex {
            padding-left: 0;
        }
        @include mobile() {
           .radio {
               padding-top: 10px !important;
               padding-left: 25px !important;
           }
           .flex-table__cell {
                padding: 0;
                width: 100% !important;
            }
            .flex-table__cell.radio {
                width: 100% !important;
            }
        }
    }
    .review {
        .alternate-bg > div:nth-of-type(even){
            background-color: #EDF6F8;
        }
        .alternate-bg > div:nth-of-type(odd){
            background-color: #FFFFFF;
        }
        .prefillData {
            // padding-bottom: 10px !important;
        }
        .grid-bg > div:nth-of-type(odd) {
            background-color: transparent;
        }
        .grid-bg {
            padding-right: 45px;
        }
        @include mobile() {
            .grid-bg {
                padding-right: 10px;
            }
            .padding-leftright10 {
                padding-left: 0;
                padding-right: 0;
            }
            .flex-table__cell .answer {
                padding-left: 0;
            }
            .edit-link-nogrid .radio {
                padding-left: 35px !important;
            }
        }
    }
}
