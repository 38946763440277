
//@import url('https://fonts.googleapis.com/css?family=Roboto:400,300,500,700,500italic,400italic,300italic|Roboto+Condensed:400,300|Roboto+Slab:300');

.help-link-text {
    // font-size: 14px;
    // font-family: Roboto;
    //padding-top: 20px;
    //padding-right: 20px;
    //padding-bottom: 25px;
    // float: right;
    //display: block;
    text-align: right;
    font-family: 'Roboto Regular', 'Roboto' , sans-serif;
    font-size: 14px;
    color: #036d9b;
    //padding-left: 195px;
    //float: right;
    //justify-content: flex-end;
}
.help-modal {
    position: fixed;
    z-index: 1;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4);
    width:100%;
    height: 100%;
    left:0;
    top:0;
}
.help-modal-content {
    margin-left: auto;
    margin-right: auto;
    margin-top: 15%;
    width: 360px;
    background: #fff none repeat scroll 0 0;
    padding: 20px;
    padding-bottom:20px;
}
.help-modal-body {
    //max-height: 350px;
    width: 320px;
    //padding: 20px;

}
.help-modal-body-text {
    color: #37424a;
    font-family: Roboto , sans-serif Regular , sans-serif;
    font-size: 18px;
}
.help-modal-p {
     line-height: 22px;
     margin-bottom:15px;
     color:#37424a;
     font-family: Roboto , sans-serif;
     font-size: 18px;
}
.help-close-icon {
    width: 25%;
    float: right;
    height: 20px;
    padding-left: 60px;
}
.help-modal-header-text {
    color: #822433;
    font-family: Roboto, sans-serif ,light;
    font-size: 26px;
    //font-weight: 300;
    float:left;
    width: 65%;
}
.help-modal-header {
    border-bottom: 1px solid #bfbfbf;
    padding-bottom: 50px;
    line-height: 34px;
    margin-bottom:20px;
}

// global styles modified
.modal-popup-cont {
    padding: 0;
}
.modal-popup {
  max-width: 575px !important;
    // width: 360px;
    // padding: 20px;
    top: 9%;
    // left: 1%;
    // h1 {
    //     // margin: 0;
    //     font-family: 'Roboto Light', 'Roboto';
    //     font-size: 34px;
    // }
    // p {
    //     color: #37424a;
    //     font-family: 'Roboto Regular', 'Roboto';
    //     font-size: 18px;
    //     line-height: 26px;
    // }
    .customer-service-text {
        font-family: 'Roboto Medium', 'Roboto' , sans-serif;
    }
}

.horizontal-top-border {
    // border-top: 1px solid #BFBFBF;
    // padding-top: 20px;
}
.modal-popup hr {
    border: none;
}
#TermsAndConditions {
    display: inline-block;
    .modal-popup {
        // width: 545px;
        padding: 20px;
        @include mobile() {
            width: 100%;
            //height: 500px;
            //margin: 90px auto;
            //top: 6%;
        }
        //height: 620px;
        margin: 20px auto;
        top: 53px;
        bottom: 20px;
    }
    .help-link-text {
        font-size: 16px;
        line-height: 26px;
    }
    p {
        font-size: 14px;
    }
    .termsandcondition-content {
        height: 430px;
        //overflow-y: scroll;
        //overflow-x: hidden;
        @include mobile(){
            height: 320px !important;
        }
    }
}

@include mobile() {
    .modal-popup {
        // margin-top: 50px;
        // width: 100%;
        h1 {
            font-size: 27px;
        }
        p {
            font-size: 15px;
        }
        // top: 0%;
        // left: 0%;
    }
}
@include tablet() {
    .modal-popup {
        // margin-top: 90px;
        // top: 6%;
        // left: 0%;
    }
}
@include desktop() {
    .modal-popup {
        // margin-top: 60px;
        // top: 9%;
        // left: 0%;
    }
}
@include hd() {
    .modal-popup {
        // margin-top: 55px;
        // top: 10%;
        // left: 0%;
    }
}

// fix for shift issue for popup modal
//.open-modal {
    //overflow-y: hidden;
//}

.customer-service-option {
  margin-left: 2px;
  font-size: 14px;
  line-height: 21px;
}

#HelpLink {
  font-size: 14px;
  outline: none;
  background: none;
  border: none;
  color: #036d9b;
  padding: 0px;
  min-width: 54px;
  cursor: pointer;
  margin-left: 5px;//20px;
  // @include mobile() {
  //   padding: 0px;
  //   min-width: 74px;
  // }
}
#HelpLink:focus, #HelpLink:hover {
  text-decoration: underline;
}
