.phy-grid {
    display: flex;
    flex-direction: column;
    font-family: 'Roboto Medium', 'Roboto' ,sans-serif;
    font-size: 16px;
    color: #37424A;
    width: 100%;
    padding: 20px 10px 0 10px;
}
.contact-div {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 0 0 20px 0;
}

@include mobile() {
    .phy-grid { 
        padding: 20px 10px 0 33px;
    } 
    .contact-div { 
        display: flex;
        flex-direction: column;
        padding: 0;
    }
    .contact-col {
        width: 100% !important;
        padding-right: 0 !important;
        padding-bottom: 20px;
    }
}
.contact-col {
    width:25%;
    padding-right:10px;
}
.reason-div {
    width:100%;
    margin-bottom: 20px;
}
.lfg-rwd-popup-scroll {
    width:100%;
}
.padright0 {
    padding-right: 0;
}

.close-icon {
    border-width: 0px;
    position: absolute;
    right: 0px;
   //top: -1px;
    width: 15px;
    height: 15px;
    opacity: 0.5;
    cursor: pointer;
    left:12px;
    margin-top:20px;
    color:#036d9b;
}
.close {
    display:inline;
    padding-right:10px;
}
.fa-times{
    color: red;
}