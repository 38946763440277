.complete-page {
  @include mobile() {
    margin-top: 20px;
  }
}
.congrats-section {
  height: 550px; //665px;

  @include mobile {
    height: auto;
    margin: 20px;
  }

  @include tablet {
    height: auto;
  }
}

.congrats-section-without-confetti {
 
  height: 475px; //650px;
  overflow: auto;
  padding-bottom: 25px;

  @include mobile {
    height: calc(100vh - 500px);
    margin: 20px;
  }

  @include tablet {
    height: auto;
  }
}

.congrats-img-article {
  text-align: center;
  position: absolute;
  z-index: auto;
  top: 10px; //40px;

  @include mobile {
    position: unset;
    top: auto;
  }
  @include tablet {
    position: unset;
    top: auto;
  }
}

.congrats-img {
  width: 30%; //42%;
  overflow: hidden;

  @include mobile {
    width: 100%;
  }
}

.congrats-h1 {
  text-align: center;
  margin-bottom: 30px;
}

.congrats-h2 {
  text-align: center;
  margin-bottom: 40px;
  h2 {
    line-height: 34px;
  }
}

.congrats-btn {
  text-align: center;
  .congrats-btn__take-survey {
    margin-left: 20px;
    @include mobile() {
      margin-left: 0px;
      margin-top: 20px;
    }
  }
}

.congrats-txt-section {
  top: 200px; //270px;
  z-index: 2;
  position: absolute;

  @include mobile {
    top: auto;
    position: unset;
    z-index: auto;
  }
  @include tablet {
    top: auto;
    position: unset;
    z-index: auto;
  }
}

.congrats-txt-section-without-confetti {
  // top: 40px;
  // z-index: 2;
  // position: absolute;

  @include mobile {
    top: auto;
    position: unset;
    z-index: auto;
  }
  @include tablet {
    top: auto;
    position: unset;
    z-index: auto;
  }
}

.congrats-reg-txt {
  font-size: 15px;
  vertical-align: super;
}


.progress-bar-width-50 {
  width: 50%!important;
}

.progress-bar-width-80 {
  width: 80%!important;
}
