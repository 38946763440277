.docusign-page {
  @include mobile() {
    padding: 20px 20px 0 20px;
  }
  .flex-container {
    display: flex;
    justify-content: space-between;
  }
  .help {
    min-width: 52px;
    margin-left: 20px;
  }
  .mg_lcn-div {
    @include mobile() {
      padding-left: 0;
    }
  }
}
.docusign-iframe-header h1 span {
  background-image: url('/src/assets/images/signature_icon.svg');
  width: 60px;
  height: 60px;
  vertical-align: top;
  margin-right: 15px;
  display: inline-block;

  @include mobile() {
    display: none;
  }
}
.docusign-parent {
  position: relative;
  width: 100%;
  overflow: auto;

  @supports (-webkit-overflow-scrolling: touch) {
    height: 600px;
  }
}
.docusign-iframe-intro_text {
  margin-top: 10px;
  margin-bottom: 10px;
}
.docusign-iframe {
  width: 100%;
  height: 600px;
}
.docusign-parent:focus {
  outline: 1px dotted #000;
}
.docusign-buttons-container {
  text-align: center;
  margin-top: 0px;
}
