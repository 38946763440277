.maintenance-page {
  .header-container {
    position: relative;
    margin-bottom: 30px;
    @include mobile() {
      position: initial;
      margin-top: 20px;
    }
    @include tablet() {
      position: initial;
      margin-top: 20px;
    }
  }
  .header-container_text {
    background-color: $ent-color;
    width: 100%;
    padding: 20px;
    display: block;
    @include mobile() {
      position: inherit;
      display: block;
      width: 100%;
      padding: 20px;
    }
    @include tablet() {
      position: inherit;
      display: block;
      width: 100%;
      padding: 20px;
    }
  }
  .header-container_text h1 {
    color: $color-heading-white;
    margin: 0;
  }
  .welcome-intro {
    font-weight: lighter;
    margin-bottom: 10px;
    @include mobile() {
      margin-bottom: 15px;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}
