
.medical {

    @import "common-form";

    .prefillData {
        width: 100%;
        padding: 0 0 20px 0;
        @include mobile(){
            padding: 0 !important;
        }
    }
    .data-col-edit-link {
        padding-top: 0;
    }
    .prefillData-dob {
        padding: 0 0 20px 0 !important;
    }
    @include mobile(){
        // ul li:nth-of-type(2n+1) {display: none;}
        ul li:nth-of-type(1) {
            display: none;
            &.ent-dropdown__option {
                display: block;
            }
        }
        .add-contact {
           // margin: 0 !important;
            margin-left:33px !important;
            width:88%;
        }
        // .page-title .title-track {
        //     width: 82%;
        // }
    }

    .grid-bg > div:nth-of-type(odd){
        background-color: #EDF6F8;
    }
    .grid-bg {
        .flex-table__cell {
            width: 75%;
        }
        .flex-table__cell.radio {
            width: 25%;
        }
        @include mobile(){
            .flex-table__cell {
                width: 100%;
            }
            .flex-table__cell.radio {
                width: 100%;
            }
        }
    }
    .height-weight {
        justify-content: flex-start;
        padding-top: 20px;
        padding-bottom: 10px;
        input[type=text] {
            padding: 0 5px !important;
            text-align: right;
            @include placeholder {
                font-style: italic;
                text-align: right;
            }
        }
        .wrap {
            width: 100%;
        }
        .text-field {
            width: 60px;
            height: 23px;
        }
        .height-weight-wrapper {
            display: flex;
            padding-top: 10px;
            padding-bottom: 10px;
        }
        .padtop0{
            padding-top:0;
        }
        .height, .weight {
            display: flex;
        }
        .hw-question {
            width: 75%;
        }
        @include mobile() {
            padding-left: 10px !important;
            padding-right: 10px !important;
            .wrap {
                width: 85%;
            }
            .height-weight-wrapper {
                flex-direction: column;
            }
            .height {
                padding-bottom: 10px;
            }
            .weight {
                padding-top: 10px;
                padding-left: 14PX;
            }
            .radio {
                padding-left: 35px;
                padding-top: 10px;
            }
            .hw-question {
                width: 100%;
            }
        }
    }

    .physician-grid {
        background-color: #EDF6F8;
        padding-bottom: 20px;
        @include mobile() {
            padding-left: 10px;
            padding-right: 10px;
        }
    }
    .add-contact {
        background-color: #FFFFFF;
        color:#036d9b;
        font-weight: 500;
        padding: 10px;
        margin-left: 10px;
        margin-right: 10px;
    }
    .inp-float--error {
        margin-bottom: 20px !important;
        //empty
    }
    .display-none {
        display:none;
    }
    .close {
        display: inline;
        padding-right: 10px;
        position: relative;
        top: 30px;
        left: -15px;
        @include mobile(){
            left:-25px;
        }
    }
    .bg-white {
        background-color: #FFFFFF;
        .inp-float__placeholder--float {
            background-color: #FFFFFF;
        }
    }
    .bg-cyan {
       background-color: #EDF6F8
    }
    .phy-grid-child {
        padding: 0 20px 0 20px;
        width:100%;
        @include mobile(){
            padding: 0 20px 0 35px;
        }
    }
    textarea {
        margin-bottom: 0;
    }
    .inp-float {
        margin-bottom: 0;
    }
    .inp-err {
        background: url(/src/assets/images/ex.png) calc(100% - 8px)/25px auto no-repeat #ffe7e7;
        border-color: #a00;
    }
    .add-margin {
        margin-bottom: 20px;
        @include mobile() {
            margin-bottom: 25px;
        }
    }
    .add-margin-textarea {
        margin-bottom: 25px;
    }
    .review {
        .alternate-bg > div:nth-of-type(even){
            background-color: #EDF6F8;
        }
        .alternate-bg > div:nth-of-type(odd){
            background-color: #FFFFFF;
        }
        .prefillData {
            // padding-bottom: 20px !important;
        }
        .grid-bg > div:nth-of-type(odd) {
            background-color: transparent;
        }
        .height-weight {
            padding: 10px 10px 0 10px;
            .radio {
                // padding-right: 55px;
            }
        }
        span {
            font-weight: bold;
        }
        .reason-div p {
            font-weight: bold;
            font-size: 16px;
            line-height: 21px;
        }
        .phy-grid {
            padding-left: 0;
            padding-right: 0;
        }
        .physician-grid {
            padding: 10px;
        }
        .phy-grid-child {
            padding: 10px 20px 10px 33px;
        }
        .reason-div {
            margin-bottom: 0;
        }
        .grid {
            padding-right: 45px;
        }
        .grid-bg {
            padding-right: 45px;
        }
        .height-weight .hw-question {
            width: 80%;
        }
        .alcohol-question {
          .radio {
            width: 3%;
          }
        }
        .alcohol-subquestion {
          .answer {
            text-align: left;
            word-break: break-all;
          }
        }
        @include mobile() {
            .prefillData {
                // padding-bottom: 20px !important;
            }
            .grid {
                padding-right: 10px;
            }
            .flex-table__cell .answer {
                padding-left: 0;
            }
            .height-weight .hw-question {
                width: 100%;
            }
            .radio {
              padding-left: 70px;
            }
            .alcohol-question {
              padding-left: 0 !important
            }
            .alcohol-subquestion {
              .radio {
                padding-left: 75px;
                padding-bottom: 10px;
              }
              .answer {
                padding-left: 0 !important
              }
            }
        }
    }

    .blank-parent-quest {
        width: 100%;
        .flexcontainer11 {
            flex-direction: column;
            justify-content: flex-start;
            padding-bottom: 20px;
            .flexcontainer {
               .flex-table__cell {
                   padding: 0;
                   width: 75%;
                }
                .flex-table__cell.radio {
                    width: 25%;
                }
            }
        }
        .subquest-wrapp-blkparent {
            width: 100%;
            @include mobile() {
                width: 88%;
            }
        }
        .subquest-wrapp-blkparent > div:last-of-type {
            padding-bottom: 10px !important;
        }
        .label-top .questindex {
            padding-left: 0;
        }
        @include mobile() {
           .radio {
               padding-top: 10px !important;
               padding-left: 25px !important;
           }
           .flex-table__cell {
                padding: 0;
                width: 100% !important;
            }
            .flex-table__cell.radio {
                width: 100% !important;
            }
        }
    }

    textarea::placeholder {
        font-style: italic;
        font-size: 18px;
        line-height: 19px;
        color: #616B72;
    }
    .city-col{
        width:23%;
    }
    .dob-col{
        width:27%;
    }

    // .data-col{
    //     width:22%;
    //     @include mobile(){
    //         width:100%;
    //     }
    // }
    // .data-col-suffix {
    //     width:12%;
    //     @include mobile(){
    //         width:100%;
    //     }
    // }
    .state-width {
        width: 16% !important;
    }
    .date-width {
        width: 36% !important;
    }
    @include mobile(){
        .state-width {
            width: 100% !important;
        }
        .date-width {
            width: 100% !important;
        }
    }
    .ent-dropdown__display {
        display: inline-block !important;
    }
    @media screen and (min-width: 750px) and (max-width: 830px){
        .add-margin {
            margin-bottom: 40px;
        }
    }

    .delete-mark {
        border-width: 0px;
        position: absolute;
        right: 0px;
        top: 5px;
        width: 10px;
        height: 10px;
        cursor: pointer;
        background: url('/src/assets/images/ICON_X_Red-01.svg') 0px 0px/10px no-repeat transparent;
        &:focus {
            outline: 1px dotted gray;
        }
    }

    .radioyesno {
      padding-right: 25px !important;
    }
    .alcohol-question {
      .radio {
        width: 25%;
      }
      @include mobile(){
        padding-left: 10px;
        .radio {
          width: 100%;
        }
      }
    }
    .alcohol-subquestion {
      .question {
        padding: 10px 20px 10px 40px;
        font-weight: normal;
        font-family: 'Roboto Medium', 'Roboto' , sans-serif !important;
        font-size: 16px !important;
        line-height: 21px
      }
      .answer {
        padding-left: 45px;
        padding-right: 40px;
      }
      .answer:nth-child(2) {
        padding-bottom: 10px;
      }
      textarea {
        height: 40px;
        resize: none;
      }
      .flex-table__cell {
        padding: 10px 0 10px 7px;
      }
      @include mobile(){
        .question {
          padding: 10px 20px 10px 50px;
        }
        .answer {
          padding-left: 75px;
        }
        textarea {
          height: 110px;
        }
        .flex-table__cell {
          padding-left: 40px;
        }
      }
    }
}
