.consent-page {
  .consent-container {
    width: 700px;
    box-sizing: border-box;
    // margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    border: 2px solid #BFBFBF;
    background-color: #FFFFFF;

    @include mobile(){
      border: none;
      width: 100%;
    }
  }

  .consent {
    height: 250px;
    font-weight: 300;
  }

  .consent-header {
    font-family: 'Roboto Light', 'Roboto', sans-serif;
    font-weight: 300;
  }

  .consent p {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400 !important;
  }

  .consent-outline {
    border: 1px solid #BFBFBF;
    background-color: #FFFFFF;
    padding: 10px 0 10px 10px;
  }

  .lfg-rwd-popup-scroll {
    margin-top: 0px;
    overflow-y: auto;
    overflow-x: auto;
    &::-webkit-scrollbar {
      width: 14px;
      height: 18px;
    }
    &::-webkit-scrollbar-thumb {
        height: 24px;
        border: 4px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
        -webkit-border-radius: 7px;
        background-color: #626B72;
        -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
    }
    &::-webkit-scrollbar-button {
      width: 0;
      height: 0;
      display: none;
    }
    &::-webkit-scrollbar-corner {
        background-color: transparent;
    }
  }

  .consent-note {
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
  }

  .consent-button {
    display: inline;
    padding-right: 30px;
    font-family: 'Roboto Medium', 'Roboto', sans-serif;

    @include mobile() {
      display: block;
      padding-bottom: 20px;
      padding-right: 0;
    }
  }
  .decline-button {
    display: inline;
    font-family: 'Roboto Medium', 'Roboto', sans-serif;
  }

  .flexcontainer-end {
    display: flex;
    justify-content: flex-end;
  }

  .scheduleTelephoneInterviewBtn {
    margin-top: 20px !important;
  }

  .lfg-rwd-popup-scroll:focus,
  .consent-outline:focus {
    // outline: 1px dotted gray;
    // outline: 1px solid #3ea6c5;
    outline: 1px dotted gray;
    outline: 1px auto -webkit-focus-ring-color;
  }

  .btn-tray__left .btn--whitebg-border {
    @include mobile() {
      margin-top: 20px;
    }
  }

  .button-container {
    display: flex;
    @include mobile() {
      display: block;
    }
  }
}
