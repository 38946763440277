.ltc {

    @import "common-form";

    @include mobile(){
        ul li:nth-of-type(1n+2) {display: none;}
    }

    .medication-answer {
      word-break: break-all;
      padding: 0 20px 0 35px;
      @include mobile() {
        padding: 0 20px 20px 65px;
      }
    }

    .inp-float__placeholder--float {
        background-color: #FFFFFF;
    }

    .nested-error-quest7 {
        font-family: "Roboto Regular", sans-serif;
        // padding-top: 10px;
        padding-left: 35px;
        color: #a00;
        @include mobile(){
            padding-left: 45px;
        }
    }

    .nested-error-quest6 {
        font-family: "Roboto Regular", sans-serif;
        // padding-top: 10px;
        padding-left: 48px;
        padding-right: 10px;
        color: #a00;
        @include mobile(){
            padding-left: 70px;
        }
    }

    .review {
        .prefillData-dob {
            padding: 0 0 20px 20px !important;
        }
        .nested-error-quest6 {
            padding-left: 43px;
            @include mobile(){
                padding-left: 75px;
            }
        }
        .nested-error-quest7 {
            padding-left: 33px;
            @include mobile(){
                padding-left: 40px;
            }
        }
    }

    .page-title_sub {
      display: flex;
      justify-content: start;
      margin-bottom: 20px;
      padding-top: 20px;
      padding-bottom: 20px;
      border-top: 1px solid #dcdee0;
      border-bottom: 1px solid #dcdee0;
      @include mobile() {
        margin: 0 20px 20px 20px !important;
      }
    }

    .answer-textarea {
      margin: 0 20px 0 35px;
      @include mobile() {
        margin: 0 20px 0 65px !important;
      }
    }

    .page-title_sub img {
      margin-right: 20px;
      width: 60px;
      height: 60px;
    }

    .page-title_sub h2 {
      margin-top: 20px !important;
    }

    .w-80per{
      width: 80% !important;
    }

    // .updt-phn{
    //   font-size: 14px;
    //   outline: none;
    //   background: none;
    //   border: none;
    //   color: #036d9b;
    //   padding: 0px;
    //   cursor: pointer;
    //   margin-left: 5px;
    // }
    // .updt-phn:focus, .updt-phn:hover {
    //   text-decoration: underline;
    // }
    // .disp-webkit-box{
    //   display: -webkit-box;
    // }

    // #HelpLink {
    //   font-size: 14px;
    //   outline: none;
    //   background: none;
    //   border: none;
    //   color: #036d9b;
    //   padding: 0px;
    //   min-width: 54px;
    //   cursor: pointer;
    //   margin-left: 5px;
    // }
}