@import "./flags";

.help {
    //empty
}

.flexcontainer {
    display: flex;
    justify-content: space-between;
}

.padright25 {
    padding-right: 25px;
}
.padbot20 {
    padding-bottom: 20px;
}
.padbot25 {
    padding-bottom: 25px;
}
.padtop20 {
    padding-top: 20px;
}
.padleft20 {
    padding-left: 20px;
}
.padright20 {
    padding-right: 20px;
}
.padbot5 {
    padding-bottom: 5px;
}
.padtop5 {
    padding-top: 5px;
}
.padleft5 {
    padding-left: 5px;
}
.pad20 {
    padding: 20px;
}
.pad10 {
    padding: 10px;
}
.padbot10 {
    padding-bottom: 10px;
}
.padtop10 {
    padding-top: 10px;
}
.padright10 {
    padding-right: 10px;
}
.padleft10 {
    padding-left: 10px;
}
.padright15 {
    padding-right: 15px;
}
.padleft15 {
    padding-left: 15px;
}
.padtop3{
    padding-top: 3px;
}
.padRight2 {
    padding-right: 2px !important;
}
.padRight0 {
    padding-right: 0;
}
.marginr20{
    margin-right:20px;
}

.disclaimer-text {
    font-family: 'Roboto Regular', 'Roboto' , sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 12px;
    line-height: 14px;
    padding-top: 20px;
    .lcn_number{
        font-size:14px;
    }
    @include mobile (){
        padding: 20px 20px 0 20px;
        width: 100%;
    }
}

.disclaimer-text p {
    font-size: 12px;
    line-height: 14px;
}

.page-title {
    @include mobile (){
        padding: 20px;
        h1 {
            //empty;
        }
    }
}

.horizontal-line {
    border-bottom: 1px solid #BFBFBF;
    margin-bottom: 10px;
}
.horizontal-top-border {
    //empty
}
.horizontal-bottom-border {
    border-bottom: 1px solid #BFBFBF;
    padding-bottom: 20px;
}

.lfg-rwd-popup-scroll {
    margin-top: 0px;
    overflow-y: auto;
    overflow-x: auto;
    &::-webkit-scrollbar {
        width: 14px;
        height: 18px;
    }
    &::-webkit-scrollbar-thumb {
        height: 24px;
        border: 4px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
        -webkit-border-radius: 7px;
        background-color: #626B72;
        -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
    }
    &::-webkit-scrollbar-button {
        width: 0;
        height: 0;
        display: none;
    }
    &::-webkit-scrollbar-corner {
        background-color: transparent;
    }
    @include mobile(){
    
        .lfg-rwd-popup-scroll {
            margin-top: 0px;
            overflow-y: auto;
            overflow-x: auto;
            &::-webkit-scrollbar {
                width: 14px;
                height: 18px;
            }
            &::-webkit-scrollbar-thumb {
                height: 24px;
                border: 4px solid rgba(0, 0, 0, 0);
                background-clip: padding-box;
                -webkit-border-radius: 7px;
                background-color: #626B72;
                -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
            }
            &::-webkit-scrollbar-button {
                width: 0;
                height: 0;
                display: none;
            }
            &::-webkit-scrollbar-corner {
                background-color: transparent;
            }
            @include mobile(){
                .lfg-rwd-popup-scroll {
                    overflow-x: auto;
                    overflow-y: hidden;
               }
           }
       }
   }
}

.loader {
    background-image: url("/../../assets/images/ajax-loader.gif") !important;
}

input[type=text]:focus {
    outline: none !important;
    border: 2px solid #58a5b9 !important;
}
textarea:focus {
    outline: none !important;
    border: 2px solid #58a5b9 !important;
}
textarea::placeholder {
    font-style: italic;
    font-size: 16px;
    color: #616B72;
}
textarea {
    width: 100%;
    border: 2px solid #BFBFBF;
    margin-bottom: 20px;
    font-family: "Roboto Regular","Roboto" , sans-serif;
    font-size: 16px;
    color: #37424A;
    padding:10px 0px 0px 10px;
    resize: none;
    height: 80px;
}
.alert-message p {
    font-size: 15px !important;
    line-height: 20px !important;
}

sup {
    top: -0.7em !important;
}

//Firefox Specific
.footer-legal ul li:nth-of-type(1n+2){
    @include mobile(){
        display:block;
    }
}

.loader-overlay {
    height: 100% !important;
}
@include mobile(){
    .footer-extras-cont {
        padding: 0 !important;
    }
    .content-wrapper {
        padding: 0 !important;
    }
    .site-navigation--no-menu {
        height: 60px !important;
    }
}

// firefox issue fix responsive page
@include mobile(){
    .main-content{
      flex: none!important;
    }
}

@include tablet(){
    .main-content{
        flex: none!important;
    }
}

.fa-plus{
    color: #822433;
}

.topheader {
    .close-window {
        display: none;
    }
}

.modal-popup__body {
    display: block !important;
}

// fix for IE 10+
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .padRight-ieonly {
        padding-right: 8px !important;
    }
    .padRight6-ieonly {
        padding-right: 6px !important;
    }
    .padRight4-ieonly {
        padding-right: 4px !important;
    }
    .review-checkbox {
        width: 100%;
    }
}
.flexcontainer-end {
    display: flex;
    justify-content: flex-end;
}

.c2c {
    color:#036D9B;
    line-height:20px;
}

.c2c--link {
    font-size: 14px;
    cursor: pointer
}

.clickToChat {
    position: fixed;
    top: 35px;
    margin-left: auto;
    margin-right: auto;
    text-align: right;
    z-index: 1100;
    @media screen and (min-width: 481px) {
        right: 22px;
    }
    @media screen and (min-width: 861px) {
        right: 22px;
    }
    @media screen and (min-width: 1226px){
        right: calc((100% - 1182px)/2);
    }
    .c2c--link {
        font-size: 16px;
        font-family: "Roboto Medium","Roboto" , sans-serif;
    }
    span {
      padding-left: 5px;
      display: inline;
    }
    .fa_custom {
        font-size: 22px;
        padding-right: 2px;
    }
    @include mobile() {
        position: absolute;
        top: -50px;
        right: 22px;
        span:nth-child(1) {
            padding-left: 5px;
        }
        span {
            display: block;
        }
    }
}

.display-mobile {
    display: none !important;
    @include mobile() {
        display: block !important;
    }
}
.display-desktop {
    display: inline !important;
    @include mobile() {
        display: none !important;
    }
}

.btn--link.btn:focus {
    background-color: transparent;
}

.header-logo:focus img {
    outline: 1px dotted gray;
}
.topheader-logo:focus img {
    outline: 1px dotted gray;
}

.btn--whitebg-border:focus {
    background-color: #FFFFFF !important;
    border: 2px solid #036d9b;
    padding: 16px 31px;
}

//PDF link
.pdf--link {
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    padding-top: 5px;
    @include mobile() {
        padding-top: 5px;
    }
    &:focus {
      text-decoration: underline;
      color:#036D9B;
    }
}
.color-blue {
    color:#036D9B;
}
.width9x{
    width: 100%;
}

.site-wide-msg-cont--warning {
  border-top: 7px solid #ce6b27;
}

.site-wide-msg__content .fa-wrench {
  color: #CE6B27;
  @include tablet() {
    margin-top: 10px;
  }
  @include mobile() {
    margin-top: 10px;
  }
}
.site-wide-msg__content p {
  font-weight: 500;
}
.banner main {
  margin-top: 135px !important;
  @include tablet() {
    margin-top: 160px !important;
  }
}
.banner .clickToChat {
  top: 85px !important;
  @include tablet() {
    top: 110px !important;
  }
  @include mobile() {
    top: -55px !important;
  }
}
@media screen and (min-width: 1000px) and (max-width: 1101px) {
  .banner .clickToChat {
    top: 110px !important;
  }
  .banner main {
    margin-top: 160px !important;
  }
}
@media screen and (min-width: 454px) and (max-width: 604px) {
  .banner main {
    margin-top: 153px !important;
  }
}
@media screen and (min-width: 368px) and (max-width: 453px) {
  .banner main {
    margin-top: 175px !important;
  }
}
@media screen and (min-width: 300px) and (max-width: 367px) {
  .banner main {
    margin-top: 195px !important;
  }
}

.heading--red {
  color: #822433;
}

/*
#otpRegistrationForm{
    div{
      div{
        .phone-number {
          display: flex;
          flex-direction: row;
           
          

            ent-dropdown{
                width:26%;
                div{
                    
                    .inp-float__placeholder{
                        line-height: 19px;
                        padding-left: 0;
                        background-color: #edf6f8!important;
                        font-family: Roboto!important;

                        top: -11px;
                        font-size: 12px;
                        text-decoration: none;
                        font-style: normal;
                        padding: 0 2px;
                        color: #37424a;

                    }

                    button{
                        .ent-dropdown__selected-option{
                            font-size: 16px;
                            width: 100%;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            white-space: nowrap;
                            padding-right: 18px;
                            .flags-option{
                               
                                .iti-flag{
                                    margin-top: 3px;
                                    text-align: center;
                                    margin-left: 25%;
                                }
                                .flags-option__name{
                                    display: none;
                                }
                                .flags-option__country-code{
                                    display: none;
                                }
                            }

                            .flags-option__country-divider{
                                display:none;
                            }
                        }
                    }
                }

                
           }

            ent-input-wrap{
                width:80%;
                .inp-float{
                    position: relative;
                    font: inherit;
                    flex: auto;
                    display: block;
                    margin-bottom: 20px;
                    .phone-number__country-code {
                        width: 45px;
                        height: 38px;
                        position: absolute;
                        padding-top: 8px;
                        margin: 2px 2px 0;
                        text-align: right;
                        line-height: 23px;
                    }
                    input[type=text] {
                        
                        padding-left: 50px;
                        border-left: none;
                    }
                    .inp-float__placeholder {
                        margin-left:15%;
                        text-overflow: ellipsis;
                    }
                }
            }

            .inp-txt--err .inp-float__placeholder.inp-float__placeholder--float {
                width: auto;
            }
        }
      }
    }
    
  }

  .flags-option{
    width: 100%;
    display: flex;
    font-size: 13px;
    text-align: center;
}
*/


.phone-number {
    .inp-float__placeholder {
        width: calc(100% - 15px);
        text-overflow: ellipsis;
        padding-left: 40px;
        line-height: 19px;
      }
      .inp-float__placeholder.inp-float__placeholder--float {
        width: auto;
      }

    display: flex;
    flex-direction: row;

    ent-input-wrap{
        .inp-float{
            width:309px;
        }
    }
  
    .ent-dropdown {
      width: 90px;
    }
  
    input[type='text'] {
      padding-left: 50px;
      border-left: none;
    }
  
    @include mobile() {
      width: 100%;
      input[type='text'] {
        width: 100%;
      }
  
      ent-input-wrap {
        width: 100%;
        
      }
  
      .inp-float__placeholder {
        font-size: 13px;
      }
    }
  
    
  
    .inp-float__placeholder--float {
      padding-left: 0;
    }
  
    .ent-dropdown__options {
      width: 275px;
    }
  
    .ent-dropdown__option {
      border-bottom: 0;
    }
  }
  
  .phone-number.inp-txt--err {
    .ent-dropdown__display {
        border-color: $color-error-text;
        border-right-color: transparent;
        background-color: $color-error-bg;
      }
    
      input[type='text'].inp-txt--err {
        border-left-color: transparent;
      }
    .inp-float__placeholder {
      width: calc(100% - 40px);
      text-overflow: ellipsis;
    }
    .inp-float__placeholder.inp-float__placeholder--float {
      width: auto;
    }
  }
  
  /* Flags section starts */
  .flags-option {
    width: 100%;
    display: flex;
    @include font-size(13px);
  }
  
  .flags-option__icon {
    margin-top: 5px;
  }
  
  .flags-option__name {
    display: inline-block;
    margin: 0 6px 0;
  }
  
  .flags-option__country-code {
    display: inline-block;
    color: #999;
  }
  
  .ent-dropdown__selected-option {
    .flags-option__icon {
      margin-top: 0;
    }
  
    .flags-option__name,
    .flags-option__country-code,
    .flags-option__country-divider {
      display: none;
    }
  }
  
  .phone-number__country-code {
    width: 12%;
    height: 38px;
    position: absolute;
    padding-top: 8px;
    margin: 2px 2px 0 2px;
    text-align: right;
    line-height: 23px;
  
    @include mobile() {
      padding-top: 10px;
    }
  }

  .instruction-text {
    align-items: center;
    font-family: Roboto , sans-serif;
    font-size: 12px;
    line-height: 17px;
    color: #37424a;
    margin-bottom: 20px;
    margin-top: -20px;
    padding: 3px;
    @include mobile() {
      width: 100%;
    }
  }
  
  .instruction-text--phone {
    font-weight: 500;
    @include mobile() {
      padding: 0 0;
    }
  }
  
  .conf-phone-number{
    ent-input-wrap{
        .inp-float{
            input[type=text]{
                padding-left: 17%;
            }
            .inp-float__placeholder{
                margin-left: 12%;
            }
        }
    }
  }
  .form-field-err {
    color: #a00;
    display: inline-block;
    font-family: Roboto Regular,sans-serif;
    font-size: 14px;
    line-height: 20px;
    margin: 5px 0 0;
}