.thankyou {
    .header-bold {
        font-weight: 400;
    }
    .heading-black {
        margin-bottom: 0 !important;
    }
    h1 {
        font-size: 34px;
        line-height: 40px;
        @include mobile() {
            font-size: 27px;
            line-height: 32px;
        }
    }
    #redirectBtn .btn {
        width: auto !important;
    }
    #redirectBtnExit {
        position: relative;
        margin: auto;
        text-align: center;
        .btn {
            background: transparent !important;
            @include mobile() {
                width: 100%;
                padding-top: 20px;
            }
        }
    }
    .done {
        padding-left: 3px;
        padding-top: 3px;
    }
    div.hr:after {
        left: 48.5%;
        @include mobile(){
            left: 46.5%;
        }
    }
    #thankYouSuccess {
        #circle {
            border: 4px solid #248476;
        }
        div.hr {
            background: #248476;
        }
        div.hr:after {
            border-color: #248476 transparent;
        }
        .content {
            background:#edf6f8;
        }
        div.bottom-triangle:after {
            border-color: #edf6f8 transparent;
        }
        .btn {
            font-size: 16px;
            font-family: "Roboto Medium" ,"Roboto" , sans-serif;
            background: #248476;
            line-height: 18px;
            &:focus {
                background-color:  #00A18F;
            }
            &:hover {
                background-color:  #00A18F;
            }
        }
    }
    #thankYouDecline {
        #circle {
            border: 4px solid #EFDA29;
            padding-left: 5px;
            padding-top: 5px;
        }
        div.hr {
            background: #EFDA29;
        }
        div.hr:after {
            border-color: #EFDA29 transparent;
        }
        .content {
            background:#FCF8D4;
        }
        div.bottom-triangle:after {
            border-color: #FCF8D4 transparent;
        }
        .btn {
            font-size: 16px;
            font-family: "Roboto Medium" ,"Roboto" , sans-serif;
            background: #248476;
            line-height: 18px;
            &:focus {
                background-color:  #00A18F;
            }
            &:hover {
                background-color:  #00A18F;
            }
        }
    }
}

.spinner {
  height: 30%;
  width: 100%;
  background: #ffffff;
  padding: 20px 20px 30px 20px !important;
  text-align: center;
}
.spinner_clock {
  padding: 20px 20px 20px 20px !important;
}
.spinner-waiting {
  height: 50px;
  width: 50px;
  border-radius: 50%;
}
.spinner-waiting_clock {
  height: 80px;
  width: 80px;
  // border-radius: 50%;
}
.spinner-done {
  padding: 10px !important;
}
.spinner-done_check-mark {
  margin-top: -10px;
  height: 100px;
  // width: 230px;
}
.docu-sign .hr-margin {
  margin-top: -15px;
}
#circle {
  width: 60px;
  height: 60px;
  background: #ffffff;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  display: inline-block;
  border: 4px solid #248476;
}
div.hr:after {
  content: "";
  position: absolute;
  border-style: solid;
  border-width: 15px 15px 0;
  border-color: #0d5da6 transparent;
  display: block;
  width: 0;
  z-index: 1;
  left: 48%;
  @include mobile() {
    left: 46%;
  }
}
.docu-sign {
  .content {
    padding: 20px 20px 20px 20px !important;
  }
  .modal-popup-cont {
    padding: 0;
    @include mobile() {
      padding: 20px;
    }
  }
  .modal-popup {
    width: 714px;
    padding: 0;
    @include mobile() {
      width: 100%;
      margin-top: 0 !important;
    }
  }
  .modal-popup__close {
    display: none;
  }
  .header {
    font-family: "Roboto Condensed Light", "Roboto", sans-serif;
    font-weight: 300;
    font-style: normal;
    font-size: 34px;
    color: #37424a;
    line-height: 40px;
    @include mobile() {
      font-size: 27px;
      line-height: 32px;
    }
  }
  .text {
    font-family: "Roboto Regular", "Roboto", sans-serif;
    font-weight: 300;
    font-style: normal;
    font-size: 18px;
    color: #37424a;
    line-height: 26px;
    @include mobile() {
      font-size: 16px;
      line-height: 21px;
    }
  }
}

#docuSignWaiting {
  #circle {
    border: 4px solid #0d5da6;
  }
  div.hr {
    background: #0d5da6;
  }
  div.hr:after {
    border-color: #0d5da6 transparent;
  }
  div.content:after {
    position: relative;
  }
  .btn {
    font-size: 16px;
    font-family: "Roboto Medium", "Roboto", sans-serif;
    background: #0d5da6;
    &:hover {
      background-color: #60b2d4;
    }
  }
}

#docuSignSuccess {
  #circle {
    border: 4px solid #248476;
    .fa-check {
      color: #248476;
    }
    .fa-3x {
      font-size: 2.3em;
      padding-top: 9px;
      padding-left: 5px;
    }
  }
  div.hr {
    background: #248476;
  }
  div.hr:after {
    border-color: #248476 transparent;
  }
  .btn {
    font-size: 16px;
    font-family: "Roboto Medium", "Roboto", sans-serif;
    background: #248476;
    &:hover {
      background-color: #00a18f;
    }
  }
  .pad20 {
    padding: 20px !important;
  }
}
#docuSignWaiting .modal-popup__header,
#docuSignSuccess .modal-popup__header {
  border-bottom: none;
  margin-bottom: 0px;
}
#docuSignWaiting .modal-popup__footer,
#docuSignSuccess .modal-popup__footer {
  margin-top: 0px;
  padding-top: 0px;
  border-top: none;
}

#redirectBtn {
  position: relative;
  margin: auto;
  text-align: center;
  background: #ffffff;
  .btn {
    width: 320px;
    @include mobile() {
      width: 100%;
    }
  }
}

.progress-bar-container {
  height: 20px;
  background-color: #dcdee0;
  margin-bottom: 20px;
  margin-top: 10px;
}
.progress-bar-width {
  height: 20px;
  width: 1%;
  background-color: #0d5da6;
}
.progress-bar-percentage {
  margin-left: 10px;
  padding-left: 104%;
  font-family: "Roboto Regular", "Roboto", sans-serif;
  font-size: 16px;
  color: #37424a;
}

.progress-bar-width-25 {
  width: 25%;
  .progress-bar-percentage {
    margin-left: 0;
  }
}
.progress-bar-width-50 {
  width: 50%;
  .progress-bar-percentage {
    margin-left: 0;
  }
}
.progress-bar-width-90 {
  width: 90%;
  .progress-bar-percentage {
    margin-left: 0;
  }
}


.docusign-thankyou {
  width: 100%;
}
.docusign-thankyou .spinner {
  padding: 20px 20px 40px 20px !important;
  @include mobile() {
    padding: 20px 20px 30px 20px !important;
  }
}
.done {
  padding-left: 3px;
  padding-top: 3px;
}
div.hr {
  width: 100%;
  height: 6px;
}
.docusign-thankyou div.hr {
  background: #248476;
}
.fa-check {
  color: #248476;
  top: 1px;
}
.fa-3x {
  font-size: 2.3em;
  padding-top: 9px;
  padding-left: 0px !important;
}
.content {
  padding: 30px 20px;
  background: #edf6f8;
}
div.bottom-triangle:after {
  content: "";
  position: absolute;
  border-style: solid;
  border-width: 10px 10px 0;
  border-color: #edf6f8 transparent;
  display: block;
  width: 0;
  z-index: 1;
  left: 49%;
  @include mobile() {
    left: 48%;
  }
}
.docusign-thankyou div.bottom-triangle:after {
  border-color: #edf6f8 transparent;
}

.docusign-thankyou div.hr:after {
  left: 48.5%;
  @include mobile() {
    left: 46.5%;
  }
  border-color: #248476 transparent;
}

.docusign-thankyou h2 {
  margin: 0px 0 15px 0;
}
.docusign-thankyou .text {
  font-family: "Roboto Regular", "Roboto", sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 18px;
  color: #37424a;
  line-height: 26px;
  @include mobile() {
    font-size: 16px;
    line-height: 21px;
  }
}

.docusign-thankyou i {
  padding-right: 0;
}

// hide header footer for docusign iframe/HIPAA state page
.docusignHIPAA .topheader ,
.docusignHIPAA .footer-overrides,
.docusignHIPAA .lcn_number {
  display: none !important;
}
.docusignHIPAA main, .docusignHIPAA main section,
.docusignHIPAA main .u-global-container {
  margin: 0px !important;
}
.docusignHIPAA .main-content .content-area {
  @include mobile() {
    padding: 0px !important;
  }
}
.docusignHIPAA main .u-global-container .content-wrapper{
  padding: 0px !important;
}
.docusignHIPAA-parent .docusign-iframe {
  height: 310px !important;
}
