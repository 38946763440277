
.transitionOverlay {
    i {
        @include mobile() {
            padding-right: 3px !important;
        }
    }
    .display-desktop {
        display: inline-block !important;
        @include mobile() {
            display: none !important;
        }
    }
}