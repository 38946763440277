$main-content-mobile-line-height: 19px;
$main-container-margin-top: 120px;
$main-background:#0D5DA6;
 
// Block
.enable-section {
    display: block;
}
 
.disable-section {
    display: none;
}
 
.progress {
    display: flex;
    align-items: flex-start;
    padding-bottom: 20px;
    @include mobile {
        padding-bottom: 38px;
        flex-direction: column;
    }
}
 
.step {
    flex: 1;
    max-width: $main-container-margin-top; //120px;
    font-size: 14px;
    line-height: $main-content-mobile-line-height; //19px;
    font-style: italic;
    font-weight: $font-weight-bold;
    color: $color-text-content-lighter;
    text-transform: lowercase;
    &+.step {
        margin-left: $main-container-margin-bottom; //20px;
        @include mobile {
            margin-top: 10px;
            margin-left: 0;
        }
    }
    @include mobile {
        display: flex;
        flex-direction: row;
        align-items: center;
        max-width: 100%;
        margin-bottom: 10px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    & :first-letter {
        text-transform: uppercase;
    }
}
 
// Element
.step__icon {
    width: 23px;
    height: 23px;
    font-style: normal;
    margin-bottom: 10px;
    border-radius: 50%;
    text-align: center;
    border: 2px solid $color-text-content-lighter;
    background: #fff;
    @include mobile {
        margin-bottom: 0;
        margin-right: 14px;
    }
    margin-left: auto !important;
    margin-right: auto !important;
    padding-top: 0px;
}
 
.step_bar {
    width: 100%;
    height: 3px;
    border-style: solid;
    border-color: #616B72;
    background-color: #616B72;
    margin-bottom: 10px;
    @include mobile() {
        margin-bottom: 5px;
        margin-right: 14px;
    }
}
.step--active {
    color: $color-heading-red;
    text-align: center;
   // color: #0D5DA6;
}
 
.step_req--active {
    color: #0D5DA6;
}
 
.step--prev {
    color: $color-link-default;
    text-align:center;
    & a {
       // text-decoration: underline;
    }
}
 
.step_bar--active {
    border-color: #0D5DA6;
    background-color: #0D5DA6;
}
.step_bar--prev {
    border-color: #248476;
    background-color: #248476;
}
.step_bar--warning {
    background-color: #b46226 !important;
}
.step_bar--warningtext {
   color: #b46226 !important;
}
.step__icon--active {
    line-height: 22px;
    background-color: $color-heading-red;
    color: $main-background;
    border: none;
}
 
.step__icon--prev {
   color: $color-link-default;
   border: 2px solid $color-link-default;
    line-height: $main-content-mobile-line-height; //19px;
}
 
.step_icon_blue {
    line-height: $main-content-mobile-line-height;
    border: 2px solid #195DA6;
    color: #0D5DA6;
    background-color: #195DA6;
    color: #fff;
}
.step_icon_green {
    line-height: $main-content-mobile-line-height; //19px;
    border :2px solid #008528;
    background-color: #008528;
    color: #fff;
    a {
      line-height: 22px;
    }
}
.step_icon_orange {
  line-height: $main-content-mobile-line-height; //19px;
  border: 2px solid #b46226;
  color:#b46226;
  a {
    line-height: 22px;
  }
}
.step_icon_orange:hover {
    background-color: #b46226;
    .fa-exclamation {
      color: #FFFFFF;
    }
    @include mobile() {
        line-height: $main-content-mobile-line-height; //19px;
        border: 2px solid #b46226;
        color: #b46226;
    }
}
.step_icon_green:hover {
    background-color: #248476;
    .done {
        color: #FFFFFF;
    }
    @include mobile() {
        line-height: $main-content-mobile-line-height; //19px;
        border: 2px solid #248476;
        color:#248476;
    }
}
 
@mixin default-display {
    background-color: #FFFFFF;
    .white-font {
        color: #0D5DA6 !important;
    }
    line-height: $main-content-mobile-line-height;
    border: 2px solid #0D5DA6;
    color: #0D5DA6;
}
.step_icon_blue:hover {
    background-color: #0D5DA6;
    .white-font {
        color: #FFFFFF !important;
    }
    @include mobile() {
        @include default-display();
    }
    @include tablet() {
        @include default-display();
    }
    @media screen and (min-width: 1024px) and (max-width: 1025px){
        @include default-display();
    }
}
.fa-exclamation {
    padding-left: 4px;
    color: #b46226;
}
.step-hr {
    max-width: 25%;
    position: relative;
    z-index: 10;
    @include mobile() {
       max-width: 100%;
    }
}
 
.step-number--active {
    color: #0D5DA6;
}
.step-number--prev {
    color: #248476;
}
 
.progress-hr {
  justify-content: space-between;
 
    .step+.step {
      margin-left: 0;
 
      @include mobile() {
        margin-top: 0;
      }
    }
 
    .step__icon {
      margin-right: 0;
      margin-bottom: 10px;
    }
 
    .step_bar {
      margin-bottom: 5px !important;
    }
 
    .step {
      margin-right: 5px;
 
      @include mobile() {
        &:last-child {
          margin-right: 0 !important;
        }
      }
    }
}
.progress.progress-hr {
    @include mobile {
        padding-bottom: 20px;
        flex-direction: row;
        height: 0px;
    }
}
@include mobile() {
    .step.step-hr {
        display:table-cell;
        vertical-align: middle;
        max-width:25%;
    }
}
@mixin align ($left, $right) {
    margin-left: $left;
    margin-right: $right;
}
.align-with-tab-4-1 { @include align (0,75%); @include mobile(){@include align (0,0); width: 100%} }
.align-with-tab-4-2 { @include align (25%,50%); @include mobile(){@include align (0,0); width: 100%} }
.align-with-tab-4-3 { @include align (50%,25%); @include mobile(){@include align (0,0); width: 100%} }
.align-with-tab-4-4 { @include align (75%,0); @include mobile(){@include align (0,0); width: 100%} }
 
.align-with-tab-3-1 { @include align (0,calc(100%/3 * 2)); @include mobile(){@include align (0,0); width: 100%} }
.align-with-tab-3-2 { @include align (calc(100%/3),calc(100%/3)); @include mobile(){@include align (0,0); width: 100%} }
.align-with-tab-3-3 { @include align (calc(100%/3 * 2),0); @include mobile(){@include align (0,0); width: 100%} }
 
.align-with-tab-2-1 { @include align (0,50%); @include mobile(){@include align (0,0); width: 100%} }
.align-with-tab-2-2 { @include align (50%,0%); @include mobile(){@include align (0,0); width: 100%} }
 
.margint20 {
    margin-top:20px;
}
.center {
    text-align:center;
}
// horizontal progress bar
 
.items {
    display: inline-block;
    padding-top: 10px;
    width: 100%;
}
// fix for IE 10+
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .items {
        width: 99.7% !important;
    }
}
 
.items li {
    float: left;
    text-transform: capitalize;
    @include mobile {
        width: 100%
    }
}
 
.arrow-right {
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-left: 4px solid #d8d8d8;
    display: inline-block;
    float: left;
    position: absolute;
    //background-color:#0D5DA6;
    @include mobile() {
        display:none;
    }
}
 
 
.arrow-in {
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-left: 4px solid #FFFFFF;
    display: inline-block;
    float: left;
    background-color: #DCDEE0;
    @include mobile(){
        display:none;
    }
}
.arrow-in-done {
    background-color: #248476;
}
.items li .outer-container {
    display: table;
    height: 30px;
    //width: 200px;
    width: 99%;
    background-color: #DCDEE0;
    float: left;
    @include mobile() {
        width: 100% !important;
    }
    &:last-child {
        width: 98%;
        @media screen and (min-width : 749px) and (max-width : 1024px) and (orientation : portrait)
        and (-webkit-min-device-pixel-ratio: 1){
            width: calc(99% - #{4px}) !important;
        }
 
    }
}
 
.items li .outer-container .inner-container {
    display: table-cell;
    vertical-align: middle;
    width: 100%;
    margin: 0 auto;
    text-align: center;
}
 
.items li .outer-container .inner-container .element a {
    color: #ffffff;
    text-decoration: none;
    padding: 0px 10px;
    display: block;
    font-size: 14px;
    font-weight: bold;
}
.element {
    font-family:'Roboto Medium', 'Roboto' ,sans-serif;
    font-size:16px;
}
 
.items li .outer-container:Hover .inner-container .element a {
    color: #FFFFFF;
    text-decoration: none;
}
 
.items li .blue-bg {
    background-color: #0D5DA6;
}
 
.blue-bgc {
    border-left: 4px solid #0D5DA6
}
 
i {
    padding-right: 5px;
}
 
.outer-container.blue-bg {
    i, span {
        color: #FFFFFF;
    }
}
 
.outer-container {
    i, span {
        color: #37427A;
    }
}
 
.done {
    color: #FFFFFF;
    // padding-left: 2px;
    // padding-top: 1px;
}
.green-bg {
    background-color: #248476 !important;
    color: #FFFFFF !important;
    .done {
        color: #FFFFFF;
    }
    span, i {
        color: #FFFFFF;
    }
}
.green-border {
    border-left: 4px solid #248476;
}
.step-number {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}
 
.step-number span {
    font-family:'Roboto Regular','Roboto' , sans-serif;
    font-size:14px;
}
 
.li-2 {
    width: 50%;
    .outer-container {
        width: 99% !important;
        //ipad air specific
        @media screen and (min-width : 749px) and (max-width : 1024px) and (orientation : portrait)
        and (-webkit-min-device-pixel-ratio: 1) {
            width: 100% !important;
        }
        // ios alignment issue fix
        @media screen and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 3) {
            width: 98.5% !important;
        }
        @media screen and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
          width: 98.5% !important;
      }
    }
    @include mobile() {
        width: 99%;
    }
}
 
.li-3 {
    width: calc(100%/3);
    &:first-child {
        .outer-container {
            width: 99%;
        }
    }
    &:nth-child(2n) {
      .outer-container {
          width: 98%;
      }
    }
    @include mobile(){
        width: 100%;
    }
}
 
// fix for IE 10+
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .items ul li:nth-child(3) {
    width: calc(100%/3.01);
  }
}
.li-4 {
    width: 25%;
    @include mobile() {
        width: 100%;
    }
}
 
.progress-container {
    // padding: 0 10px 0 10px;
    @include mobile() {
        padding: 10px 20px 0 20px !important;
    }
    a {
        text-decoration: none !important;
    }
}
 
a {
  font-size: 14px;
}
 
 
.progress-hr .step-hr:not(:last-child):after {
  position: absolute;
  content: "";
  border-bottom: 5px solid #7F7F7F;
  width: 100%;
  top: 10px;
  left: 57%;
  z-index: 1;
 
}
 
.progress-hr .step-hr.completed:not(:last-child):after {
  border-bottom: 5px solid #008528;
 
}
 
.progress-hr .step-hr.completed .step__icon {
  background: #008528;
  a .fa-check.done{
    color: #fff;
  }
}