
// $fa-font-path: "../node_modules/font-awesome/fonts";
// @import "../node_modules/font-awesome/scss/font-awesome.scss";

@import "node_modules/@styles/ent-styles/core/colors";
@import "node_modules/@styles/ent-styles/core/config";
@import "node_modules/@styles/ent-styles/mixins/breakpoints";
@import "node_modules/@styles/ent-styles/mixins/forms";


@import 'node_modules/@styles/ent-styles/mixins/fonts.scss';
@import 'node_modules/@styles/ent-styles/mixins/utils.scss';


@import "app/styles/components/login.component";
@import "app/styles/components/consent.component";
@import "app/styles/components/welcome.component";
@import "app/styles/components/helpmodal.component";
@import "app/styles/components/longtermcare.component";
@import "app/styles/components/common.component";
@import "app/styles/components/medical-information.component";
@import "app/styles/components/medical-information-part-iii.component";
@import "app/styles/components/physician-grid.component";
@import "app/styles/components/animation-help.component";
@import "app/styles/components/docusign-overlay.component";
@import "app/styles/components/thank-you.component";
@import "app/styles/components/transition-overlay.component";
@import "app/styles/components/medication-grid.component";
@import "app/styles/components/complete-page.component.scss";
@import "app/styles/components/medical-info-ais.component.scss";
@import "app/styles/components/docusign-iframe.component.scss";
@import "app/styles/components/clarify-questions.component.scss";
@import "app/styles/components/maintenance-page.component.scss";
@import "app/styles/components/save-and-exit.component.scss";

@import "app/styles/components/grid";
@import "app/styles/components/progress-bar.component";
@import "app/styles/components/progress-steps.component";
@import "app/styles/components/phone-num-registration.scss";
@import "app/styles/components/otp-recieving-device.scss";
@import "app/styles/components/otp-verification.scss";
@import "app/styles/components/otp-common-form.scss";
@import "app/styles/components/otp-modal.scss";

.site-navigation {
  height: 77px !important;
}
main {
  margin-top: 87px !important;
  // margin-bottom: 20px !important;
  @include mobile() {
    margin-top: 67px !important;
    // margin-bottom: 20px !important;
  }
}

.u-global-container {
  max-width: 1182px !important;
}
.footer-overrides {
  background: #23292e !important;
  margin-top: 0 !important;
  padding-left: 0 !important;
}
.topheader-logo {
  width: 140px;
  height: 40px;
}
.header-logo:focus img {
  outline: 1px dotted gray;
}
.topheader-logo:focus img {
  outline: 1px dotted gray;
}
@include above(999px) {
  .site-navigation {
    height: 70px;
  }
}
h1 {
  margin: 0 0 20px 0;
}

.logoCenter .content-area {
  @include mobile() {
    padding: 0px !important;
  }
}

.screen-reader-only {
  border: 0 none;
  clip: rect(0px,0px,0px,0px);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px
}

@include mobile() {
  .main-content--mobile {
    padding: 0 20px;
  }
  .footer-overrides {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .footer-legal {
    margin-top: 0;
  }
  .content-wrapper {
    padding: 0 !important;
  }
  .site-navigation--no-menu {
    height: 67px !important;
  }
  .disclaimer-text + p {
    margin-top: 0;
  }
  .system-error {
    margin-left: 20px;
    margin-right: 20px;
  }
}
@include below(530px) {
  .footer-legal-nav-menu__item:last-child {
    border-left: none;
    padding-left: 0;
    padding-top: 10px;
  }
}


// fix for IE 10+
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  @include mobile() {
    .site-navigation {
      max-height: 60px !important;
    }
    .topheader {
      position: fixed !important;
      top: 0px !important;
    }
    .main {
      margin-top: 95px !important;
    }
    :last-child.footer-legal-nav-menu__item {
      padding-top: 0px !important;
    }
  }
}

