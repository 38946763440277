// Block
.grid {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

// Element
.grid__item{
//empty
}

// Modifier
.grid--col2 {
    .grid__item{
        width: 50%;
        @include mobile() {
            width: 100%
        }
    }
    .grid__item:last-of-type {
      padding-left: 20px;
      @include mobile() {
        padding-left: 10px;
      }
    }
}
.grid--col3 {
    .grid__item{
        width: 33.33%;
        @include mobile() {
            width: 100%
        }
    }
}
.grid--col4 {
    .grid__item{
        width: 25%;
        @include mobile() {
            width: 100%
        }
    }
}
