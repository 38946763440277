.otp-common-form .help {
    padding-right: 30px;

    .modal-popup__header {
        border-bottom: 1px solid #dcdee0;
        margin-bottom: 25px;
    }

    .modal-popup__footer {
        margin-top: 25px;
        padding-top: 15px;
        border-top: 1px solid #dcdee0;
    }

    .modal-popup {
        position: relative;
        background: #fff;
        margin: 0 auto;
        top: 9%;
        padding: 40px;
        width: 55%;
        margin-bottom: 20px;
        max-width: 575px !important;
    }

}

.main-container {
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    width: 360px;
    border: 2px solid #BFBFBF;
    background-color: #FFFFFF;

    @include mobile() {
        border: 0 !important;
        border-left: none;
        border-right: none;
        width: 100%;
    }
}

.upper-box {
    padding: 20px;
}

.header-text  {
    font-family: 'Roboto Light', 'Roboto' , sans-serif;
    font-size: 34px;
    line-height: 40px;
    @include mobile(){
       font-size:27px;
       line-height: 32px;
    }
    margin-bottom: 0px;
    padding-bottom: 20px;
}

.main-content .header-body {
    font-family: 'Roboto Light', 'Roboto' , sans-serif;
    font-size: 18px;
    line-height: 26px;
    @include mobile(){
        font-size: 15px;
        line-height: 21px;
    }
}

.otp-common-form {
    .header-body {
        font-weight: 300;
    }
    .consent-note {
        font-weight: 400;
        font-size: 18px;
        line-height: 26px;
        @include mobile(){
            font-size: 15px;
            line-height: 21px;
        }
    }
}

.lower-box {
    background-color: #EDF6F8;
    padding: 20px;
    box-sizing: border-box;
}

#submitBtn {
    font-weight: normal;
    font-family: 'Roboto Medium', 'Roboto' , sans-serif;
    #submit {
        // width: 316px;
        width: 100%;
        font-size: 16px !important;
        @include mobile(){
            width: 100%;
        }
    }
    #decline, #consent {
        font-size: 16px !important;
        @include mobile(){
            .btn {
                display: block;
            }
        }
    }
    @include mobile(){
        .flexcontainer {
            display: block;
            justify-content: none;
        }
        .flexcontainer div:nth-child(1) {
            padding-bottom: 20px;
        }
    }

    .btn--whitebg-border{
        color: #036d9b;
        padding-left: 30px;
    }
    .consent-button {
        display: inline;
        padding-right: 30px;
        @include mobile(){
            display: block;
            padding-bottom: 20px;
            padding-right: 0;
        }
        .consent-btntext{
            font-family: 'Roboto Medium', 'Roboto' , sans-serif;
        }
    }
    .decline-button {
        display: inline;
        .consent-btntext{
            font-family: 'Roboto Medium', 'Roboto' ,sans-serif;
        }
    }
}

.radio-phn-type {
    cursor: not-allowed;
    label, span, input {
        cursor: not-allowed;
        pointer-events: none;
    }
}

.radio {
    font-family: "Roboto Regular", "Roboto", sans-serif;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    
    .phn-type-lbl {
        color: #37424A;
        font-family: "Roboto Regular", "Roboto", sans-serif;
        font-size: 16px;
        line-height: 26px;
    }

    // Custom radio buttons
    /* The container */
    .container {
        display: block;
        position: relative;
        padding-left: 35px;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    /* Hide the browser's default radio button */
    .container input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }

    /* Create a custom radio button */
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: #d8d8d8;//white;
        border: 1px solid #37424A;
        border-radius: 50%;
        cursor: not-allowed;
    }

    /* On mouse-over, add a grey background color */
    .container:hover input~.checkmark {
        background-color: #eee;
        border: 1px solid #37424A;
    }

    /* When the radio button is checked, add a blue background */
    .container input:checked~.checkmark {
        background-color: #d8d8d8;//white;
        border: 1px solid #37424A;
    }

    /* Create the indicator (the dot/circle - hidden when not checked) */
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    /* Show the indicator (dot/circle) when checked */
    .container input:checked~.checkmark:after {
        display: block;
    }

    /* Style the indicator (dot/circle) */
    .container .checkmark:after {
        top: 3px;
        left: 3px;
        width: 13px;
        height: 13px;
        border-radius: 50%;
        background: #822433;
    }
}

.arrow-help{
    display: flex;
    justify-content: space-between;
}

.arrow-color {
    color: #036d9b;
}

.otp-phn-num {
    margin-top: 20px;
    input {
        color: #37424a !important;
        background-color: #d8d8d8 !important;
        border-color: #bfbfbf !important;
        opacity: 1 !important;
        -webkit-text-fill-color: #37424a !important;
    }
}

.resend-otp {
    margin-top: 20px;
    display: flex;
    .link{
        text-decoration: none;
        color: #036d9b;
        line-height: 25px;
        cursor: pointer;
    }
    .link:hover{
        text-decoration: underline;
    }
    .disable-regenOtp{
        text-decoration: none;
        color: red;//grey;
        line-height: 25px;
        cursor: not-allowed;
        border: none;
        background: none;
        padding: 0px;
        font-family: Roboto, sans-serif;
        font-weight: 400;
        font-size: 16px;
    }
    .act-as-lbl{
        text-decoration: none;
        color: #036d9b;
        line-height: 25px;
        cursor: pointer;
        border: none;
        background: none;
        padding: 0px;
        font-family: Roboto, sans-serif;
        font-weight: 400;
        font-size: 16px;
    }
}

.ml-5 {
    margin-left: 5px;
}

.mr-5{
    margin-right: 5px;
}

.otp-type {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 20px;
    .otp-call, .otp-text {
        border: 1px solid #d8d8d8;
        background-color: white;
        width: 50%;
        height: 45px;
        text-align: center;
        line-height: 45px;
    }
    .otp-call {
        margin-right: 20px;
    }
    .otp-call:hover, .otp-text:hover {
        cursor: pointer;
    }
    .selected-otp-type {
        border: 2px solid #036d9b;
        color: #036d9b;
        font-weight: bold;
    }
}

